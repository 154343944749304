import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { 
  Button, Card, CardBody, CardHeader, CardTitle, Col, FormFeedback, FormGroup, Input, InputGroup, 
  InputGroupText, Label, Row 
} from "reactstrap";
import Dialog from "../../shared/components/Dialog";

class AddEditTeam extends Component {
  state = {
    showPassword: false,
    old_pw: "",
    new_pw: "",
    new_pw_confirm: "",
    new_pw_confirm_pristine: true,
    has_min_chars: false,
    has_uppercase: false,
    has_number: false,
    has_symbol: false,
    showConfirmDelete: false
  }

  passwordRequirementsCheck = (value) => {
    this.setState({ new_pw: value })

    // check min chars
    if(value.length >= 8) {
      this.setState({ has_min_chars: true })
    } else {
      this.setState({ has_min_chars: false })
    }

    // check if has uppercase
    if(value.toLowerCase() !== value) {
      this.setState({ has_uppercase: true })
    } else {
      this.setState({ has_uppercase: false })
    }

    // check if has number
    const matches = value.match(/\d+/g);
    if (matches !== null) {
      this.setState({ has_number: true })
    } else {
      this.setState({ has_number: false })
    }

    // check if has symbol
    const matches2 = value.match(/[!-/:-@[-`{-~]/gm);
    if (matches2 !== null) {
      this.setState({ has_symbol: true })
    } else {
      this.setState({ has_symbol: false })
    }
  }

  render() {
    const { 
      showPassword,
      old_pw,
      new_pw,
      new_pw_confirm,
      new_pw_confirm_pristine,
      has_min_chars,
      has_number,
      has_symbol,
      has_uppercase,
      showConfirmDelete
    } = this.state

    let editId = null
    if(this.props.match.params) {
      editId = this.props.match.params.id
    }

    return (
      <div className="main-content view-account">
        <div className="page-heading">
          <h2 className="page-title">
            {editId ? 'Edit' : 'Add'} team member
          </h2>
          <div className="page-heading-controls">
            <NavLink className="btn btn-secondary" to="/app/team" exact>Back</NavLink>
            {editId &&
            <Button color="danger" onClick={() => this.setState({ showConfirmDelete: true })}>Delete account</Button>
            }
            <Button color="primary">
              Save {editId ? 'changes' : ''}
            </Button>
          </div>
        </div>
        <div className="page-content">
          <Row>
            <Col xl={7}>
              <Card>
                <CardHeader>Account info</CardHeader>
                <CardBody>
                  <FormGroup row>
                    <Col sm={6}>
                      <Label>Username</Label>
                      <Input />
                    </Col>
                    <Col sm={6}>
                      <Label>Email</Label>
                      <Input type="email" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={6}>
                      <Label>First Name</Label>
                      <Input />
                    </Col>
                    <Col sm={6}>
                      <Label>Last Name</Label>
                      <Input />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  {editId ? 'Change' : 'Account'} Password
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={6}>
                      {editId &&
                      <FormGroup>
                        <Label>Old Password</Label>
                        <InputGroup>
                          <Input
                            type={showPassword ? 'text' : 'password'}
                            value={old_pw}
                            onChange={e => this.setState({ old_pw: e.target.value })}
                          />
                          <InputGroupText
                            role="button"
                            onClick={() => {
                              this.setState({ showPassword: !showPassword })
                            }}
                          >
                            <span className={`icon icon-${showPassword ? 'invisible' : 'visible'}`}></span>
                          </InputGroupText>
                        </InputGroup>
                      </FormGroup>
                      }
                      <FormGroup>
                        <Label>Password</Label>
                        <InputGroup>
                          <Input
                            type={showPassword ? 'text' : 'password'}
                            value={new_pw}
                            onChange={e => this.passwordRequirementsCheck(e.target.value)}
                          />
                          <InputGroupText
                            role="button"
                            onClick={() => {
                              this.setState({ showPassword: !showPassword })
                            }}
                          >
                            <span className={`icon icon-${showPassword ? 'invisible' : 'visible'}`}></span>
                          </InputGroupText>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label>Confirm Password</Label>
                        <InputGroup>
                          <Input
                            type={showPassword ? 'text' : 'password'}
                            value={new_pw_confirm}
                            onChange={e => this.setState({ 
                              new_pw_confirm: e.target.value,
                              new_pw_confirm_pristine: false
                            })}
                            invalid={!new_pw_confirm_pristine && (new_pw !== new_pw_confirm)}
                          />
                          <InputGroupText
                            role="button"
                            onClick={() => {
                              this.setState({ showPassword: !showPassword })
                            }}
                          >
                            <span className={`icon icon-${showPassword ? 'invisible' : 'visible'}`}></span>
                          </InputGroupText>
                          <FormFeedback>Passwords do not match</FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <CardTitle>Password requirements:</CardTitle>
                      <ul className="password-checklist">
                        <li className={has_min_chars ? "check" : ""}>Minimum 8 characters</li>
                        <li className={has_uppercase ? "check" : ""}>Contains at least 1 uppercase letter</li>
                        <li className={has_number ? "check" : ""}>Contains at least 1 number</li>
                        <li className={has_symbol ? "check" : ""}>Contains at least 1 symbol</li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={5}>
              <Card>
                <CardHeader>Account settings</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label>Scan result visibility mode</Label>
                    <Select
                      options={[
                        {value: 'full', label: 'Full'},
                        {value: 'hidden', label: 'Hidden'},
                      ]}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Trigger Set</Label>
                    <Select
                      options={[
                        {value: 1, label: 'Default Trigger Set'},
                        {value: 2, label: 'Thrift Store'},
                        {value: 3, label: 'Library'}
                      ]}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {showConfirmDelete &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ showConfirmDelete: false })}
          type="delete"
          title="Delete account"
          message="Are you sure want to delete this account?"
          deleteButtonText="Delete account"
          onDelete={() => console.log('delete account then back to team member list page')}
        />
        }
      </div>
    )
  }
}

export default AddEditTeam;