import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { clearTokenLocal, saveTokenLocal } from "../../helpers/auth/utils";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import userActions from "../user/actions";
import { client } from "../../config/api";
import { refreshTokenInternalAPI } from "../../helpers/auth/token_refresh";
import { sdMessage } from "../../helpers/utils";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* userLogin() {
	yield takeLatest(actions.USER_LOGIN, function*(payload) {
	    try {
  			const { data } = payload;
  			const res = yield call(apiCalls.post, apiEndpoints.profiles.login, data);
	  		if (res.data.error) {
		  		yield put(actions.userLoginError(res.data));
  			 	sdMessage(res.data.error);
  			} else {
	  			yield put(actions.userLoginSuccess(res.data));
		  		saveTokenLocal(res.data);
			  	yield put(userActions.userSetDataSuccess(res.data.user_data));
    			yield put(push("/app/dashboard"));
          window.location.reload();
			}
		} catch (error) {
			console.log('userLogin - ERROR!', error);
			yield put(actions.userLoginError({}));
			sdMessage('Login error')
		}
	})
}

export function* userLogout() {
	yield takeLatest(actions.USER_LOGOUT, function*(payload) {
		const { data } = payload;
		clearTokenLocal();
		yield put(userActions.userSetDataSuccess({}));
    yield put(push(data?.data || "/login"));
	})
}

export function* userRefreshToken() {
	yield takeLatest(actions.USER_REFRESH_TOKEN, function*(payload) {
	    try {
			const res = yield refreshTokenInternalAPI(client).catch(
				err => {throw err;});
			if (!res?.access) {
				yield put(actions.userRefreshTokenError());
			} else {
				if(res.access){
          const data = {
            access_token: res.access,
            refresh_token: res.refresh,
          };
					yield put(actions.userRefreshTokenSuccess(data));
					saveTokenLocal(data);
				}
			}
		} catch (error) {
      yield put(actions.userLogout("/login"));
		}
	})
}

export function* userRegister() {
  yield commonSaga(
    actions.USER_REGISTER,
    apiCalls.post,
    apiEndpoints.profiles.registration,
    {
      ...default_config,
      ...{
        success_show: false,
        redirect_on_success: "/login"
      }
    }
  );
}

export function* userRegisterSMSCodeSend() {
  yield commonSaga(
    actions.USER_REGISTER_SMS_CODE_SEND,
    apiCalls.post,
    apiEndpoints.profiles.registration_sms_send,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* authSaga() {
	yield all([
		fork(userLogin),
		fork(userLogout),
		fork(userRefreshToken),
		fork(userRegister),
		fork(userRegisterSMSCodeSend),
	]);
}
