import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, Form, Label, Input, Button, InputGroup, InputGroupText } from 'reactstrap';
import { NavLink } from "react-router-dom";
import authActions from "../../redux/auth/actions";
import { withRouter } from "react-router";
import moment from "moment";
import { ALmark, logo } from "../../assets/images";

const { userLogin } = authActions;

class Login extends Component {
  state = {
    showPassword: false,
    un: "",
    pw: "",
  }

  updateValue = (key, data) => {
    this.setState({ [key]: data });
  }

  login = () => {
    const { un, pw } = this.state;
    this.setState({ pw: ""})
    const data = {
      username_or_email: un,
      password: pw,
    }
    this.props.userLogin(data);
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    this.login();
  }

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  render() {
    return (
      <div className="view-login">
        <div className="centered-content-wrapper">
          <div className="auth-box">
            <div className="auth-box-logo">
              <img src={logo} alt="Logo" />
            </div>
            <Card>
              <CardBody>
                <h1 className="auth-box-title">Sign in to your account</h1>
                <div className="login-accelerlist-info">
                  <span><img src={ALmark} alt="" /></span>
                  <span>AccelerList user? Please use your AccelerList account to sign in.</span>
                </div>
                <Form onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                    <Label>Username</Label>
                    <Input
                      placeholder="Username"
                      onChange={e => this.updateValue("un", e.target.value)}
                      value={this.state.un}
                    />
                  </div>
                  <div className="form-group">
                    <div className="hstack justify-content-between">
                      <Label>Password</Label>
                      <Label><NavLink to="/forgot_password">Forgot your password?</NavLink></Label>
                    </div>
                    <InputGroup className="input-group-has-addon">
                      <Input
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder="Password"
                        onChange={e => this.updateValue("pw", e.target.value)}
                        value={this.state.pw}
                      />
                      <InputGroupText>
                        <span
                          className={`icon ${this.state.showPassword? 'icon-invisible' : 'icon-visible'}`}
                          role="button"
                          onClick={this.toggleShowPassword}
                        ></span>
                      </InputGroupText>
                    </InputGroup>
                  </div>
                  <div className="form-group">
                    <Label><Input type="checkbox" /> Remember me</Label>
                  </div>
                  <Button
                    color="primary"
                    size="lg"
                    block
                    disabled={this.props.login_working}
                  >Sign in</Button>
                </Form>
              </CardBody>
            </Card>
            <div className="auth-box-footer">
              <p>Don't have account? <NavLink to="/register">Sign Up</NavLink></p>
              <p>&copy; AccelerList.com {moment().format('YYYY')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      login_failed: state.Auth.get("login_failed"),
      login_failed_message: state.Auth.get("login_failed_message"),
      login_working: state.Auth.get("login_working"),
    }),
    {
      userLogin,
    }
  )(Login)
);
