import moment from "moment";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { NavLink } from "react-router-dom";
import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import RegisterForm from "./form";
import { logo } from "../../assets/images";
import { appConfig } from "../../config/app";

class Register extends Component {
  render() {
    return (
      <div className="view-register">
        <div className="centered-content-wrapper">
          <div className="auth-box">
            <div className="auth-box-logo">
              <img src={logo} alt="Logo" />
            </div>
            <Card>
              <CardBody>
                <h1 className="auth-box-title">Create an account</h1>
                <StripeProvider
                  apiKey={appConfig.stripeApiTestKey}
                >
                  <Elements>
                    <RegisterForm />
                  </Elements>
                </StripeProvider>
              </CardBody>
            </Card>
            <div className="auth-box-footer">
              <p>Already have an account? <NavLink to="/login">Sign In</NavLink></p>
              <p>&copy; AccelerList.com {moment().format('YYYY')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
  }),
  {
  }
)(Register);
