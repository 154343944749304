import React, { Component } from 'react';
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { digitСonversion } from '../../helpers/utils';
import { Button } from "reactstrap";
import Dialog from "../../shared/components/Dialog";
import Loading from "../../shared/components/Loading";
import triggersActions from "../../redux/triggers/actions";

const {
  triggerTriggerAdd,
  triggerTriggerUpdate,
  triggerTriggerDelete,
} = triggersActions;

const fba_slot_options = [
  {value: 'Skip', label: 'Skip'},
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
]

const mf_slot_options = [
  {value: 'Skip', label: 'Skip'},{value: '1', label: '1'},{value: '2', label: '2'},{value: '3', label: '3'},
  {value: '4', label: '4'},{value: '5', label: '5'},{value: '6', label: '6'},{value: '7', label: '7'},
  {value: '8', label: '8'},{value: '9', label: '9'},{value: '10', label: '10'},{value: 'Highest', label: 'Highest'},
]

const yes_no_options = [
  {value: 'Yes', label: 'Yes'},
  {value: 'No', label: 'No'},
]

class TriggersTable extends Component {
  state = {
    showDeleteAll: false,
  }

  componentDidMount = () => {
  }

  generateColumns = () => {
    const columns = [
      {
        text: 'Trigger #',
        formatter: (cell, row, rowIndex) => rowIndex + 1,
        editable: false
      },
      {
        dataField: 'min_pscore',
        text: 'Min. pScore',
        editorClasses: 'form-control-sm',
        editCellStyle: { maxWidth: '107px' },
        formatter: (cell, row) => <span>{digitСonversion(cell, 'number')}</span>
      },
      {
        dataField: 'max_pscore',
        text: 'Max. pScore',
        editorClasses: 'form-control-sm',
        editCellStyle: { maxWidth: '107px' },
        formatter: (cell, row) => <span>{digitСonversion(cell, 'number')}</span>
      },
      {
        dataField: 'min_rank',
        text: 'Min. Rank',
        editorClasses: 'form-control-sm',
        editCellStyle: { maxWidth: '107px' },
        formatter: (cell, row) => <span>{digitСonversion(cell, 'number')}</span>
      },
      {
        dataField: 'max_rank',
        text: 'Max. Rank',
        editorClasses: 'form-control-sm',
        editCellStyle: { maxWidth: '107px' },
        formatter: (cell, row) => <span>{digitСonversion(cell, 'number')}</span>
      },
      {
        dataField: 'fba_slot',
        text: 'FBA Slot',
        editor: {
          type: Type.SELECT,
          options: fba_slot_options
        },
        editorClasses: 'form-control-sm',
        formatter: (cell, row) => <span>{cell}</span>
      },
      {
        dataField: 'used_slot',
        text: 'Used Slot',
        editor: {
          type: Type.SELECT,
          options: mf_slot_options
        },
        editorClasses: 'form-control-sm',
        style: { width: '110px' },
        formatter: (cell, row) => <span>{cell}</span>
      },
      {
        dataField: 'bb_compare',
        text: 'Compare BB?',
        editor: {
          type: Type.SELECT,
          options: yes_no_options
        },
        editorClasses: 'form-control-sm',
        formatter: (cell, row) => <span>{cell ? 'Yes' : 'No'}</span>
      },
      {
        dataField: 'percentage_of_amazon',
        text: '% Off Amazon',
        editorClasses: 'form-control-sm',
        editCellStyle: { maxWidth: '50px' },
        formatter: (cell, row) => <span>{digitСonversion(cell, 'percent')}</span>
      },
      {
        dataField: 'target_profit',
        text: 'Target Profit',
        editorClasses: 'form-control-sm',
        editCellStyle: { maxWidth: '50px' },
        formatter: (cell, row) => <span>{digitСonversion(cell, 'currency')}</span>
      },
      {
        dataField: 'always_reject',
        text: 'Always Reject?',
        editor: {
          type: Type.SELECT,
          options: yes_no_options
        },
        editorClasses: 'form-control-sm',
        formatter: (cell, row) => <span>{cell ? 'Yes' : 'No'}</span>
      },
      {
        dataField: 'df1',
        isDummyField: true,
        text: 'Delete',
        editable: false,
        hidden: this.props.viewOnly ? true : false,
        classes: 'text-center',
        formatter: (cell, row) => (
          <Button
            color="link"
            className="p-0"
            disabled={this.props.triggers_working}
            onClick={() => this.props.triggerTriggerDelete({
              id: row.id,
            })}
          >
            <span className="icon icon-trash bg-danger"></span>
          </Button>
        )
      }
    ]
    return columns;
  }

  onTableChange = (type, { data, cellEdit: { rowId, dataField, newValue } }) => {
    if(dataField === 'bb_compare' || dataField === 'always_reject') {
      newValue = newValue === 'Yes' ? true : false;
    }
    this.props.triggerTriggerUpdate({
      id: rowId,
      update_data: {
        [dataField]: newValue,
      }
    });
  }

  addTrigger = () => {
    this.props.triggerTriggerAdd({
      id_template: this.props.trigger.id,
      id_category: this.props.id_category,
    });
  }

  render() {
    console.log("data", this.props.data);
    return (
      <div>
        <div className="hstack justify-content-between gap-1 mb-3">
          <p className="m-0">
          {!this.props.viewOnly && <span className="text-danger">Click table cell to edit value, then click outside table or hit enter to save.</span>}
          </p>
          {this.props.triggers_working
            ? <Loading text="Saving..." size={25} inline />
            : <span className="text-success"><span className="icon icon-check bg-success"></span> Done</span>
          }
        </div>
        {!this.props.triggers_working &&
        <React.Fragment>
          <BootstrapTable
            keyField="id"
            columns={this.generateColumns()}
            data={this.props.data}
            bordered={false}
            noDataIndication={<div className="text-center">You don't have any trigger for this category. Please add a trigger</div>}
            striped={true}
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true
            })}
            remote={true}
            onTableChange={this.onTableChange}
            wrapperClasses={this.props.triggers_working || this.props.viewOnly ? "disabled" : ""}
            classes="triggers-table"
          />
          {!this.props.viewOnly &&
          <div className="hstack justify-content-between mt-2">
            <Button
              color="primary"
              size="sm"
              onClick={this.addTrigger}
              disabled={this.props.triggers_working}
            >Add trigger</Button>
            {/*
            <Button
              color="danger"
              size="sm"
              onClick={() => this.setState({ showDeleteAll: true })}
              disabled={this.props?.data?.length === 0 || this.props.triggers_working}
            >Delete all</Button>
            */}
          </div>
          }
        </React.Fragment>
        }
        {this.state.showDeleteAll &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ showDeleteAll: false })}
          type="delete"
          title="Delete all triggers"
          message="Are you sure want to delete all triggers in this category?"
          deleteButtonText="Delete all triggers"
          onDelete={() => console.log('on delete all triggers')}
        />
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    trigger: state.Triggers.get("trigger"),
    triggers_working: state.Triggers.get("triggers_working"),
    userData: state.User.get("userData"),
  }),
  {
    triggerTriggerAdd,
    triggerTriggerUpdate,
    triggerTriggerDelete,
  }
)(TriggersTable);
