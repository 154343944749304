import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import AdminReportRequest from "./AdminReportRequest";
import './style.css';

class Admin extends Component {
  state = {
    activeTab: "1"
  }
  render() {
    const { activeTab } = this.state

    return (
      <div className="main-content view-admin">
        <h2 className="page-title">Admin</h2>
        <div className="page-content">
          <div className="tabbed-content">
            <Nav tabs>
              <NavItem>
                <NavLink
                  role="button"
                  active={activeTab === "1"}
                  onClick={() => this.setState({ activeTab: "1" })}
                >Bug Report/Feature Request</NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {activeTab === "1" &&
                <AdminReportRequest />
                }
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    )
  }
}

export default Admin;