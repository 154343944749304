import React, { Component } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer
} from 'recharts';
import moment from "moment";
import chart_config from "./chart_config";

class ChartSearchAccept extends Component {

  formatXAxis = (str) => {
    return str
      ? moment(str).format("M/D")
      : ""
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={this.props.data}
          margin={{
            top: 30,
            left: -10
          }}
        >
          <CartesianGrid
            stroke={chart_config.gridStroke}
            vertical={false}
          />
          <XAxis
            dataKey="date"
            stroke={chart_config.axisStroke}
            tick={{ fill: chart_config.axisFill }}
            tickFormatter={(e) => this.formatXAxis(e)}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: chart_config.axisFill }}
          />
          <Tooltip
            cursor={false}
            contentStyle={chart_config.tooltipContentStyle}
            labelStyle={{ color: chart_config.axisFill }}
            itemStyle={{ color: chart_config.axisFill }}
            labelFormatter={(e) => this.formatXAxis(e)}
          />
          <Legend
            formatter={chart_config.legendFormatter}
          />
          {/*
          <Bar
            dataKey="search"
            name="Search"
            maxBarSize={chart_config.maxBarSize}
            radius={chart_config.barRadius}
            fill={chart_config.colorLightBlue}
          />
          */}
          <Bar
            dataKey="count"
            name="Accept"
            maxBarSize={chart_config.maxBarSize}
            radius={chart_config.barRadius}
            fill={chart_config.colorBlue}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

export default ChartSearchAccept;
