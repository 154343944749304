import { Map } from "immutable";

const initState = new Map({
  bug_reports: null,
  bug_reports_working: false,
});

export default function adminActionsReducer(state = initState, action){
	switch (action.type) {

		case "ADMIN_DELETE_BUG_REPORT":
			return state
				.set("bug_reports_working", true)
		case "ADMIN_DELETE_BUG_REPORT_SUCCESS":
      var delete_bug_reports = state.get("bug_reports");
      delete_bug_reports.items = delete_bug_reports.items.filter(item => item.id !== action.data.data.id);
			return state
				.set("bug_reports", {...delete_bug_reports})
				.set("bug_reports_working", false)
		case "ADMIN_DELETE_BUG_REPORT_FAILED":
			return state
				.set("bug_reports_working", false)

		case "ADMIN_UPDATE_BUG_REPORT":
			return state
				.set("bug_reports_working", true)
		case "ADMIN_UPDATE_BUG_REPORT_SUCCESS":
      var update_bug_reports = state.get("bug_reports");
      update_bug_reports.items = update_bug_reports.items.map(item => {
        if(item.id === action.data.data.id){
          item = {...action.data.data};
        }
        return item;
      })
			return state
				.set("bug_reports", {...update_bug_reports})
				.set("bug_reports_working", false)
		case "ADMIN_UPDATE_BUG_REPORT_FAILED":
			return state
				.set("bug_reports_working", false)

		case "ADMIN_GET_ALL_BUG_REPORTS":
			return state
				.set("bug_reports_working", true)
		case "ADMIN_GET_ALL_BUG_REPORTS_SUCCESS":
			return state
				.set("bug_reports", {...action.data.data})
				.set("bug_reports_working", false)
		case "ADMIN_GET_ALL_BUG_REPORTS_FAILED":
			return state
				.set("bug_reports_working", false)

		default:
			return state;
  }
}
