import { all, fork } from "redux-saga/effects";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* getAllBugReports() {
  yield commonSaga(
    actions.ADMIN_GET_ALL_BUG_REPORTS,
    apiCalls.get,
    apiEndpoints.application.admin_report_bug,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* updateBugReport() {
  yield commonSaga(
    actions.ADMIN_UPDATE_BUG_REPORT,
    apiCalls.put,
    apiEndpoints.application.admin_report_bug,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* deleteBugReport() {
  yield commonSaga(
    actions.ADMIN_DELETE_BUG_REPORT,
    apiCalls.delete,
    apiEndpoints.application.admin_report_bug,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export default function* adminSaga() {
	yield all([
		fork(getAllBugReports),
		fork(updateBugReport),
		fork(deleteBugReport),
	]);
}
