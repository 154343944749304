import axios from "axios";
import defaults from "superagent-defaults";
import { getTokenLocal } from "../helpers/auth/utils";
import { createAxiosResponseInterceptorForClient } from "../helpers/auth/token_refresh";
import { appConfig } from "./app";

const apiEndpoints = {
  profiles: {
    login: "/profiles/login/",
    token_refresh: "/profiles/token/refresh/",
    settings: "/profiles/settings/",
    user: "/profiles/user/",
    user_amazon_auth_star: "/profiles/user/amazon/auth/start",
    registration: "/profiles/registration/",
    registration_sms_send: "/profiles/registration/sms/send",
    subscription_billing: "/profiles/subscription/billing",
    subscription_delete: "/profiles/subscription/delete",
    subscription_reactivate: "/profiles/subscription/reactivate",
    subscription_cards: "/profiles/subscription/cards",
  },
  application: {
    template_pricing_all: "/application/template/pricing/all",
    template_pricing: "/application/template/pricing",
    template_pricing_category: "/application/template/pricing/category",
    template_pricing_trigger: "/application/template/pricing/trigger",
    template_pricing_activate: "/application/template/pricing/activate",
    dashboard_history_cumulative: "/application/dashboard/history/cumulative",
    dashboard_chart_accepted: "/application/dashboard/chart/accepted",
    dashboard_chart_revenue: "/application/dashboard/chart/revenue",
    report_bug: "/application/report/bug",
    admin_report_bug: "/application/admin/report/bug",
  }
};

const env =
  process.env.REACT_APP_NODE_ENV || appConfig.environments["production"];

const API_ROOT = appConfig.backendApiUrl[env];

const client = axios.create({ baseURL: API_ROOT });

const superagent = defaults();

const request = {
  init: () =>
    superagent.set(
      "Authorization",
      `Bearer ${getTokenLocal().toJS().access_token}`
    ),
  delete: (url) => request.init().delete(url),
  get: (url) => request.init().get(url),
  post: (url) => request.init().post(url),
  put: (url) => request.init().put(url),
};

client.defaults.headers.common["Authorization"] = `Bearer ${
  getTokenLocal().toJS().access_token
}`;

const interceptor = createAxiosResponseInterceptorForClient(client);

interceptor();

export { request, API_ROOT, client, apiEndpoints };
