import React, { Component } from 'react';
import { Label, Modal, ModalBody } from "reactstrap";
import './style.css';
//import { appConfig } from "../../../config/app";
import ReactivateForm from "./form";
import { Elements, StripeProvider } from "react-stripe-elements";
import { appConfig } from "../../../config/app";

const features = [
  'pScore™ profit calculation',
  'Downloadable database',
  'SBYB integration',
  'Smart triggers',
  'Speed mode',
  'Profit-based approach',
  'Add-to-batch functionality',
  'Custom MSKUs'
]

class SubscribeModal extends Component {

  render() {
    const {
      isOpen,
    } = this.props;

    return (
      <Modal isOpen={isOpen} centered>
        <ModalBody>
          <h6 className="mb-4">Subscribe PRO plan for $19.99/month and discover the potential of every product on Amazon with a single scan.</h6>
          <ul className="features-list">
            {features.map((item, idx) => (
            <li key={`pro-${idx}`}>{item}</li>
            ))}
          </ul>
          <hr />
          <Label>Please provide your payment information</Label>
          <StripeProvider
            apiKey={appConfig.stripeApiTestKey}
          >
            <Elements>
              <ReactivateForm
                userData={this.props.userData}
                userReactivatePlan={this.props.userReactivatePlan}
                user_billing_working={this.props.user_billing_working}
              />
            </Elements>
          </StripeProvider>
        </ModalBody>
      </Modal>
    )
  }
}

export default SubscribeModal;
