import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { cardAmex, cardDiscover, cardMastercard, cardOthers, cardVisa } from "../../assets/images";
import { Elements, StripeProvider } from "react-stripe-elements";
import { appConfig } from "../../config/app";
import PaymentCardForm from "./PaymentCardForm";

const card_providers = {
  'amex' : cardAmex,
  'discover' : cardDiscover,
  'mastercard' : cardMastercard,
  'visa' : cardVisa,
  'others' : cardOthers
}

class Payment extends Component {
  componentDidMount = () => {
    this.props.userGetCards();
  }

  render() {

    const card = this.props.cards ? this.props.cards[0] : null;
    const card_provider = card
      ? card_providers[card['brand'].toLowerCase()]
      : card_providers["others"];

    return (
      <div className="payment">
        <Row>
          <Col xl={5}>
            <Card className="h-100">
              <CardHeader>Your payment info</CardHeader>
              <CardBody>
                <p><img src={card_provider} alt={card ? card["brand"] : "others"} /> •••• •••• •••• {card ? card["last4"] : null}</p>
                <span className="text-muted">Expires {card ? `${card["exp_month"]}/${card["exp_year"]}` : null}</span>
              </CardBody>
            </Card>
          </Col>
          <Col xl={5}>
            <Card>
              <CardHeader>Update your payment</CardHeader>
              <CardBody>
              <StripeProvider
                  apiKey={appConfig.stripeApiTestKey}
                >
                  <Elements>
                    <PaymentCardForm
                      userData={this.props.userData}
                      userUpdateCard={this.props.userUpdateCard}
                      user_billing_working={this.props.user_billing_working}
                    />
                  </Elements>
                </StripeProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Payment;
