const dashboardActions = {
  DASHBOARD_CUMULATIVE_GET: "DASHBOARD_CUMULATIVE_GET",
  DASHBOARD_ACCEPTED_CHART_GET: "DASHBOARD_ACCEPTED_CHART_GET",
  DASHBOARD_REVENUE_CHART_GET: "DASHBOARD_REVENUE_CHART_GET",

  dashboardRevenueChartGet: (data) => ({
    type: dashboardActions.DASHBOARD_REVENUE_CHART_GET,
    data,
  }),

  dashboardAcceptedChartGet: (data) => ({
    type: dashboardActions.DASHBOARD_ACCEPTED_CHART_GET,
    data,
  }),

  dashboardCumulativeGet: (data) => ({
    type: dashboardActions.DASHBOARD_CUMULATIVE_GET,
    data,
  }),
}

export default dashboardActions;
