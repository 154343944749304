import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import './style.css';

class LeftNavigation extends Component {
  render() {
    const { userData } = this.props;

    return (
      <div className="left-navigation">
        <Nav vertical>
          {userData?.is_staff &&
          <NavItem>
            <NavLink
              to="/app/admin"
              className="nav-link"
            ><span className="icon icon-20 icon-admin"></span> <span>ADMIN</span></NavLink>
          </NavItem>
          }
          <NavItem>
            <NavLink
              to="/app/dashboard"
              className="nav-link"
            ><span className="icon icon-20 icon-home"></span> <span>Dashboard</span></NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="/app/triggers"
              className="nav-link"
            ><span className="icon icon-20 icon-triggers"></span> <span>Triggers</span></NavLink>
          </NavItem>
          {/*
          <NavItem>
            <NavLink
              to="/app/team"
              className="nav-link"
            ><span className="icon icon-20 icon-team"></span> <span>Team</span></NavLink>
          </NavItem>
          */}
          <NavItem>
            <NavLink
              to="/app/settings"
              className="nav-link"
            ><span className="icon icon-20 icon-settings"></span> <span>Settings</span></NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="/app/account"
              className="nav-link"
            ><span className="icon icon-20 icon-user"></span> <span>Account</span></NavLink>
          </NavItem>
        </Nav>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
  }),
  {
  }
)(LeftNavigation);
