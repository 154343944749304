import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* dashboardCumulativeGet() {
  yield commonSaga(
    actions.DASHBOARD_CUMULATIVE_GET,
    apiCalls.get,
    apiEndpoints.application.dashboard_history_cumulative,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* dashboardAcceptedChartGet() {
  yield commonSaga(
    actions.DASHBOARD_ACCEPTED_CHART_GET,
    apiCalls.get,
    apiEndpoints.application.dashboard_chart_accepted,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* dashboardRevenueChartGet() {
  yield commonSaga(
    actions.DASHBOARD_REVENUE_CHART_GET,
    apiCalls.get,
    apiEndpoints.application.dashboard_chart_revenue,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* dashboardSaga() {
	yield all([
    fork(dashboardCumulativeGet),
    fork(dashboardAcceptedChartGet),
    fork(dashboardRevenueChartGet),
	]);
}
