import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  //Button,
  Card, CardBody, CardHeader, Col, FormGroup, Input,
  //InputGroup,
  Label, Row
} from "reactstrap";
//import Select from 'react-select';
import { FlagIcon } from "react-flag-kit";
//import MaskedInput from 'react-text-mask';
import userActions from "../../redux/user/actions";

const {
  userSaveData,
} = userActions;

const country_codes = [
  {
    value: 'US',
    label: <span>
            <FlagIcon code="US" className="rounded-1" /> +1
          </span>
  },
  {
    value: 'CA',
    label: <span>
            <FlagIcon code="CA" className="rounded-1" /> +1
          </span>
  },
  {
    value: 'GB',
    label: <span>
            <FlagIcon code="GB" className="rounded-1" /> +44
          </span>
  }
]

class Account extends Component {
  state = {
    selected_country: country_codes[0]
  }

  render() {
    const { userData } = this.props;
    /*
    let phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    if(this.state.selected_country !== null && this.state.selected_country.value === "GB") {
      phoneMask = [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    }
    */

    return (
      <div className="main-content view-account">
        <h2 className="page-title">Account</h2>
        <div className="page-content">
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader>My account details</CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label>Username</Label>
                    <Input
                      readOnly
                      disabled
                      value={userData?.username}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      readOnly
                      disabled
                      value={userData?.email}
                    />
                  </FormGroup>
                  {/*
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <InputGroup>
                      <Select
                        options={country_codes}
                        value={this.state.selected_country}
                        onChange={(selected) => this.setState({ selected_country: selected })}
                      />
                      <MaskedInput
                        mask={phoneMask}
                        onChange={e =>
                          this.setState({
                            phone: e.target.value.replace(/\D+/g, ''),
                          })
                        }
                        className="form-control"
                        showMask={true}
                        keepCharPositions={true}
                        value={this.state.phone}
                      />
                    </InputGroup>
                  </FormGroup>
                  <Button color="primary">Update</Button>
                  */}
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardHeader>Account settings</CardHeader>
                <CardBody>
                  <div className="hstack justify-content-between gap-1">
                    <span>Send me payment reminders and Amazon industry news</span>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={userData?.send_amazon_industry_news ? true : false}
                        onChange={() => this.props.userSaveData({
                          update_data: {
                            send_amazon_industry_news: !userData?.send_amazon_industry_news
                          }
                        })}
                      />
                    </FormGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
  }),
  {
    userSaveData,
  }
)(Account);
