const adminActions = {
	ADMIN_GET_ALL_BUG_REPORTS: 'ADMIN_GET_ALL_BUG_REPORTS',
	ADMIN_UPDATE_BUG_REPORT: 'ADMIN_UPDATE_BUG_REPORT',
	ADMIN_DELETE_BUG_REPORT: 'ADMIN_DELETE_BUG_REPORT',

	deleteBugReport: (data) => ({
		type: adminActions.ADMIN_DELETE_BUG_REPORT,
    data,
	}),

	updateBugReport: (data) => ({
		type: adminActions.ADMIN_UPDATE_BUG_REPORT,
    data,
	}),

	getAllBugReports: (data) => ({
		type: adminActions.ADMIN_GET_ALL_BUG_REPORTS,
    data,
	}),
}

export default adminActions;

