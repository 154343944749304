import React, { Component } from 'react';
import { connect } from "react-redux";
import {
  Form, Input, InputGroup, InputGroupText, Label,
  Button, Row, Col, FormGroup, Alert,
  Modal, ModalBody, ModalHeader, FormText, } from "reactstrap";
import {
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  injectStripe,
} from "react-stripe-elements";
import { withRouter } from "react-router";
import authActions from "../../redux/auth/actions";
import Select from 'react-select';
import MaskedInput from "react-text-mask";

const { userRegister, userRegisterSMSCodeSend } = authActions;
const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const countryPhoneOptions = [
  {value: '+1', label: 'US (+1)'},
  {value: '+1', label: 'CA (+1)'},
]
let timer;

class RegisterForm extends Component {
  state = {
    showPassword: true,
    un: "",
    email: "",
    pw: "",
    pw_confirm: "",
    cc_number_valid: false,
    cc_expiry_valid: false,
    cc_cvc_valid: false,
    ok_reminders: true,
    phone: null,
    phone_country: null,
    phone_sms_code: null,
    showVerifyModal: false,
    resend_code_timer: 0
  }

  componentDidMount = () => {
    this.startTimer()
  }

  startTimer = () => {
    this.setState({ resend_code_timer: 30 })
    timer = setInterval(() => {
      let resend_code_timer = this.state.resend_code_timer
      if(resend_code_timer <= 0) {
        clearInterval(timer)
      } else {
        resend_code_timer--
      }
      this.setState({ resend_code_timer })
    }, 1000)
  }

  updateValue = (key, data) => {
    this.setState({ [key]: data });
  }

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  createPhoneNumber = () => {
    const {phone, phone_country} = this.state;
    if(!phone || !phone_country){ return "";}
    return `${phone_country?.value}${phone}`
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    this.props.stripe
      .createToken({ name: this.state.username })
      .then(response => {
        if(response.token){
          this.setState({
            stripe_error: null,
          })
          let authData = {
            username: this.state.un,
            email: this.state.email,
            password: this.state.pw,
            stripe_token: response.token.id,
            stripe_plan: "live_plus_database",
            ok_reminders: this.state.ok_reminders,
            phone: this.createPhoneNumber(),
            confirm_code_sms: this.state.phone_sms_code,
          };
          this.props.userRegister(authData);
        } else {
          this.setState({stripe_error: response?.error?.message})
        }
      })
  }

  render() {
    //const { userData } = this.props;

    const style = {
      base: {
        backgroundColor: '#fff' ,
        color: 'rgba(39,42,57, .75)',
        fontSize: '14px',
        '::placeholder': {
          color: 'rgba(39,42,57, .3)'
        },
        ':-webkit-autofill': {
          color: 'rgba(39,42,57, .75)',
          textFillcolor: 'rgba(39,42,57, .75)',
          boxShadow: `0 0 0px 40rem #fff} inset`,
          outline: 0,
        },
      },
      invalid: {
        color: '#b64057',
        iconColor: '#b64057'
      }
    }

    return (
      <Form onSubmit={this.onFormSubmit}>
        <Row>
          <Col xl={6}>
            <div className="form-group">
              <Label>Username</Label>
              <Input
                placeholder="Username"
                onChange={e => this.updateValue("un", e.target.value)}
                value={this.state.un}
                autoComplete="off"
              />
            </div>
          </Col>
          <Col xl={6}>
            <div className="form-group">
              <Label>Email</Label>
              <Input
                placeholder="Email address"
                type="email"
                onChange={e => this.updateValue("email", e.target.value)}
                value={this.state.email}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <div className="form-group">
              <Label>Password</Label>
              <InputGroup className="input-group-has-addon">
                <Input
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="new-password"
                  onChange={e => this.updateValue("pw", e.target.value)}
                  value={this.state.pw}
                />
                <InputGroupText>
                  <span
                    className={`icon ${this.state.showPassword? 'icon-invisible' : 'icon-visible'}`}
                    role="button"
                    onClick={this.toggleShowPassword}
                  ></span>
                </InputGroupText>
              </InputGroup>
            </div>
          </Col>
          <Col xl={6}>
            <div className="form-group">
              <Label>Confirm Password</Label>
              <InputGroup className="input-group-has-addon">
                <Input
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  onChange={e => this.updateValue("pw_confirm", e.target.value)}
                  value={this.state.pw_confirm}
                />
                <InputGroupText>
                  <span
                    className={`icon ${this.state.showPassword? 'icon-invisible' : 'icon-visible'}`}
                    role="button"
                    onClick={this.toggleShowPassword}
                  ></span>
                </InputGroupText>
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <FormGroup>
              <Label>Phone Number</Label>
              <div className="d-flex gap-2">
                <div style={{ minWidth: 170 }}>
                  <Select
                    placeholder="Select country"
                    options={countryPhoneOptions}
                    onChange={(e) => this.setState({phone_country: e})}
                  />
                </div>
                <MaskedInput
                  mask={phoneMask}
                  onChange={e => this.setState({ phone: e.target.value.replace(/\D+/g, '') })}
                  className="form-control"
                  showMask={true}
                  keepCharPositions={true}
                  value={this.state.phone}
                />
                <div style={{ flex: '1 0 auto' }}>
                <Button
                  color="info"
                  onClick={(e) => {
                    this.setState({showVerifyModal: true})
                    this.props.userRegisterSMSCodeSend({
                      username: this.state.un,
                      email: this.state.email,
                      phone: this.createPhoneNumber(),
                    })
                  }}
                  disabled={
                    this.props.registration_working
                      || !this.state.un
                      || !this.state.pw
                      || !this.state.email
                      || (this.state.pw !== this.state.pw_confirm)
                      || !this.state.phone
                      || !this.state.phone_country
                  }
                >Send Code</Button>
                </div>
              </div>
              <FormText>We will send confirmation code to this number.</FormText>
            </FormGroup>
            {this.state.showVerifyModal &&
              <Modal isOpen={this.state.showVerifyModal} centered>
                <ModalHeader
                  toggle={() => this.setState({showVerifyModal: !this.state.showVerifyModal})}
                >Verify phone number</ModalHeader>
                <ModalBody className="d-flex gap-3">
                  <div>
                    <svg width="80" height="90" xmlns="http://www.w3.org/2000/svg">
                      <g fillRule="nonzero" fill="#3C404C">
                      <path d="M15.79 65a2.966 2.966 0 0 1-1.962-.741 2.812 2.812 0 0 1-.832-3.029l.99-3.003c-3.352-.913-5.699-3.726-5.699-7.126V32.705c0-4.096 3.442-7.429 7.673-7.429h56.367c4.23 0 7.673 3.333 7.673 7.429v18.396c0 4.096-3.441 7.429-7.672 7.429l-41.588.068-13.662 6.109A2.994 2.994 0 0 1 15.79 65Zm-4.503-32.295v18.396c0 2.387 2.042 4.292 4.75 4.431l.413.021c.78.04 1.309.811 1.064 1.553l-1.601 4.856L30.057 55.6l42.271-.07c2.576 0 4.673-1.986 4.673-4.429V32.705c0-2.442-2.097-4.429-4.673-4.429H15.96c-2.577 0-4.673 1.987-4.673 4.429Z"/>
                      <path d="m27.154 42.091-1.918-1.063 1.918-1.063a1.5 1.5 0 0 0-1.455-2.624l-2.056 1.14v-2.203a1.5 1.5 0 1 0-3 0v2.203l-2.056-1.14a1.502 1.502 0 0 0-2.04.584 1.502 1.502 0 0 0 .584 2.04l1.918 1.063-1.918 1.063a1.5 1.5 0 0 0 1.455 2.625l2.056-1.14v2.203a1.5 1.5 0 1 0 3 0v-2.203l2.056 1.14a1.501 1.501 0 0 0 1.456-2.625ZM41.947 42.091l-1.918-1.063 1.918-1.063a1.5 1.5 0 0 0-1.455-2.624l-2.057 1.141v-2.203a1.5 1.5 0 1 0-3 0v2.203l-2.056-1.14a1.5 1.5 0 0 0-1.455 2.624l1.918 1.063-1.918 1.063a1.5 1.5 0 0 0 1.455 2.625l2.056-1.14v2.203a1.5 1.5 0 1 0 3 0v-2.203l2.057 1.141a1.501 1.501 0 1 0 1.455-2.627ZM56.739 42.091l-1.918-1.063 1.918-1.063a1.5 1.5 0 0 0-1.455-2.624l-2.056 1.14v-2.203a1.5 1.5 0 1 0-3 0v2.203l-2.056-1.14a1.5 1.5 0 0 0-1.455 2.624l1.918 1.063-1.918 1.063a1.5 1.5 0 0 0 1.455 2.624l2.056-1.14v2.203a1.5 1.5 0 1 0 3 0v-2.203l2.056 1.14a1.498 1.498 0 0 0 2.039-.584 1.5 1.5 0 0 0-.584-2.04ZM72.739 42.091l-1.918-1.063 1.918-1.063a1.5 1.5 0 0 0-1.455-2.624l-2.056 1.14v-2.203a1.5 1.5 0 1 0-3 0v2.203l-2.056-1.14a1.5 1.5 0 0 0-1.455 2.624l1.918 1.063-1.918 1.063a1.5 1.5 0 0 0 1.455 2.624l2.056-1.14v2.203a1.5 1.5 0 1 0 3 0v-2.203l2.056 1.14a1.498 1.498 0 0 0 2.039-.584 1.5 1.5 0 0 0-.584-2.04Z"/>
                      <path d="M46.303 89.833H8.689C3.897 89.833 0 85.935 0 81.144V8.689C0 3.898 3.898 0 8.689 0h37.614c4.791 0 8.689 3.898 8.689 8.689v11.144a1.5 1.5 0 1 1-3 0V8.689A5.695 5.695 0 0 0 46.303 3H8.689A5.695 5.695 0 0 0 3 8.689v72.454a5.695 5.695 0 0 0 5.689 5.689h37.614a5.696 5.696 0 0 0 5.689-5.689V62.667a1.5 1.5 0 0 1 3 0v18.477c.001 4.791-3.898 8.689-8.689 8.689Z"/>
                      <path d="M36.337 11.521H18.664c-2.847 0-5.164-2.402-5.164-5.355V2.515h3v3.651c0 1.298.971 2.355 2.164 2.355h17.673c1.192 0 2.163-1.057 2.163-2.355V2.515h3v3.651c0 2.953-2.316 5.355-5.163 5.355Z"/>
                      </g>
                    </svg>
                  </div>
                  <div>
                    <p>We've sent verification code to your mobile phone. Please enter code you receive.</p>
                    <div className="d-flex gap-2 mb-2">
                      <Input
                        style={{ width: 100 }}
                        onChange={(e) => this.setState({phone_sms_code: e.target.value})}
                      />
                      <Button
                        color="primary"
                        onClick={(e) => {
                          this.setState({showVerifyModal: !this.state.showVerifyModal})
                        }}
                      >Verify</Button>
                    </div>
                    {/* <small className="text-danger">Please enter correct verification code.</small> */}
                    <div className="mt-4">
                      <span className="text-muted me-2">Didn't receive the code?</span>
                      <Button
                        size="sm"
                        onClick={(e) => {
                          this.props.userRegisterSMSCodeSend({
                            username: this.state.un,
                            email: this.state.email,
                            phone: this.createPhoneNumber(),
                          })
                          this.startTimer()
                        }}
                        disabled={
                          this.props.registration_working
                          || this.state.resend_code_timer > 0
                        }
                        style={{ minWidth: 100 }}
                      >{`${this.state.resend_code_timer === 0 ? 'Resend code' : `Wait (${this.state.resend_code_timer}s)`}`}</Button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            }
          </Col>
        </Row>
        <Label>Payment Information</Label>
        <React.Fragment>
          <FormGroup row>
            <Col sm={6}>
              <CardNumberElement
                onChange={(e) => {
                  this.setState({ cc_number_valid: e.complete && !e.error })
                }}
                style={style}
              />
            </Col>
            <Col sm={3} xs={6}>
              <CardExpiryElement
                onChange={(e) => {
                  this.setState({ cc_expiry_valid: e.complete && !e.error })
                }}
                style={style}
              />
            </Col>
            <Col sm={3} xs={6}>
              <CardCVCElement
                onChange={(e) => {
                  this.setState({ cc_cvc_valid: e.complete && !e.error })
                }}
                style={style}
              />
            </Col>
          </FormGroup>
        </React.Fragment>
        {this.state.un
         && this.state.pw
         && this.state.email
         && (this.state.pw === this.state.pw_confirm)
         && this.state.cc_number_valid
         && this.state.cc_expiry_valid
         && this.state.cc_cvc_valid &&
        <React.Fragment>
        <Alert color="light" className="py-2">
          <small>
            By creating your account you agree to a 7 day free trial of Profit Hunt™. We will automatically charge your credit
            card after 7 days if you do not cancel. We will also send you an email reminder 2 days before your free trial ends
            to remind you of the upcoming charge. Happy Hunting!
          </small>
        </Alert>
        <FormGroup check className="mb-2">
          <Label>
            <Input
              type="checkbox"
              checked={this.state.ok_reminders}
              onChange={() => this.setState({ ok_reminders: !this.state.ok_reminders })}
            /> It's ok to send me payment reminders and Amazon industry news.
          </Label>
        </FormGroup>
        </React.Fragment>
        }
        <Button
          color="primary"
          block
          size="lg"
          disabled={
            this.props.registration_working
              || !this.state.un
              || !this.state.pw
              || !this.state.email
              || (this.state.pw !== this.state.pw_confirm)
              || !this.state.phone
              || !this.state.phone_country
              || !this.state.phone_sms_code
          }
        >Create Account</Button>
      </Form>
    )
  }
}

export default withRouter(connect(
  state => ({
    registration_working: state.Auth.get("registration_working"),
    registration_data: state.Auth.get("registration_data"),
  }),
  {
    userRegister,
    userRegisterSMSCodeSend,
  }
)(injectStripe(RegisterForm)));
