import moment from "moment";
import { connect } from "react-redux";
import React, { Component } from 'react';
import Select from "react-select";
import { Badge, Button, Modal, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Dialog from "../../shared/components/Dialog";
import Loading from "../../shared/components/Loading";
import adminActions from "../../redux/admin/actions";

const {
  getAllBugReports,
  updateBugReport,
  deleteBugReport,
} = adminActions;

const filterFeedbackOptions = [
  {value: null, label: 'All types'},
  {value: 'bug', label: 'Bug'},
  {value: 'feature', label: 'Feature'}
]

const statusOptions = [
  {value: 'open', label: 'Open'},
  {value: 'on_progress', label: 'On Progress'},
  {value: 'closed', label: 'Closed'},
]

const filterStatusOptions = [
  {value: null, label: 'All Status'},
  ...statusOptions
]

class AdminReportRequest extends Component {
  state = {
    activeFilterType: filterFeedbackOptions[0],
    activeFilterStatus: filterStatusOptions[0],
    showImgUrl: null,
    deleteReportId: null,
    page: 1,
  }

  componentDidMount = () => {
    this.props.getAllBugReports();
  }

  changeReportStatus = (id, status) => {
    this.props.updateBugReport({
      id: id,
      update_data: {report_status: status},
    })
  }

  nextPage = () => {
    const { page } = this.state;
    const { bug_reports } = this.props;
    const next_page = bug_reports.num_pages > page + 1 ? page + 1 : bug_reports.num_pages;
    this.setState({page: next_page});
    this.props.getAllBugReports({
      page: next_page,
    });
  }

  previousPage = () => {
    const { page } = this.state;
    const previous_page = page - 1 ? page - 1 : 1;
    this.setState({page: previous_page});
    this.props.getAllBugReports({
      page: previous_page,
    });
  }

  selectReportType = (report_type) => {
    this.setState({
      page: 1,
      activeFilterType: report_type,
    });
    this.props.getAllBugReports({
      page: 1,
      report_type: report_type.value,
      status: this.state.activeFilterStatus?.value,
    })
  }

  selectReportStatus = (report_status) => {
    this.setState({
      page: 1,
      activeFilterStatus: report_status,
    });
    this.props.getAllBugReports({
      page: 1,
      report_type: this.state.activeFilterType?.value,
      status: report_status.value,
    })
  }

  render() {
    const {
      activeFilterType,
      activeFilterStatus,
      showImgUrl,
      deleteReportId,
    } = this.state;

    const {
      bug_reports,
      bug_reports_working,
    } = this.props;

    if(bug_reports_working) {
      return (
        <div className="text-center">
          <Loading text="Loading data..." />
        </div>
      )
    }
    else {
      return (
        <div className="admin-report-request">
          <div className="admin-top-controls">
            <div style={{ minWidth: '7.5rem' }}>
              <Select
                options={filterFeedbackOptions}
                value={activeFilterType}
                onChange={(selected) => this.selectReportType(selected)}
                isSearchable={false}
              />
            </div>
            <div style={{ minWidth: '9.7rem' }}>
              <Select
                options={filterStatusOptions}
                value={activeFilterStatus}
                onChange={(selected) => this.selectReportStatus(selected)}
                isSearchable={false}
              />
            </div>
          </div>
          {bug_reports?.items?.length > 0 ?
          <React.Fragment>
          <div className="admin-reports">
            {bug_reports?.items?.map(item => (
            <div className="admin-reports-item" key={`report-item-${item.id}`}>
              <div className="admin-reports-content">
                <div className="admin-reports-header">
                  <h3 className="admin-reports-title">{item.title}</h3>
                  <Badge
                    className="admin-reports-type"
                    color={item.report_type === 'bug' ? 'danger' : 'info'}
                  >{item.report_type.toUpperCase()}</Badge>
                </div>
                <p className="admin-reports-description">{item.description}</p>
                <div className="admin-reports-footer">
                  <span className="admin-reports-timestamp">{moment(item.created_at).from()}</span>
                  •
                  <span className="admin-reports-user">{item.id_user}</span>
                  {item.image_url &&
                  <React.Fragment>
                  •
                  <span
                    role="button"
                    onClick={() => this.setState({ showImgUrl: item.image_url })}
                    className="admin-reports-image icon icon-14 icon-image bg-info"
                  ></span>
                  </React.Fragment>
                  }
                </div>
              </div>
              <div className="admin-reports-controls">
                <div style={{ minWidth: '9rem' }}>
                  <Select
                    options={statusOptions}
                    value={statusOptions.filter(x => x.value === item.report_status)[0]}
                    onChange={(selected) => this.changeReportStatus(item.id, selected.value)}
                    disabled={bug_reports_working}
                  />
                </div>
                <span
                  role="button"
                  className="icon icon-trash bg-danger"
                  onClick={() => this.setState({ deleteReportId: item.id })}
                ></span>
              </div>
            </div>
            ))}
          </div>
          <Pagination listClassName="justify-content-center" className="mt-4">
            <PaginationItem disabled={bug_reports_working || this.state.page <= 1}>
              <PaginationLink
                onClick={() => this.previousPage()}
                disabled={bug_reports_working || this.props.page <= 1}
              >Previous</PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={bug_reports_working || bug_reports?.num_pages <= this.state.page}>
              <PaginationLink
                onClick={() => this.nextPage()}
                disabled={bug_reports_working || bug_reports?.num_pages <= this.state.page}
              >Next</PaginationLink>
            </PaginationItem>
          </Pagination>
          </React.Fragment>
          : <p className="text-center my-3">No bug report or feature request found.</p>
          }

          {showImgUrl &&
          <Modal isOpen={true} centered toggle={() => this.setState({ showImgUrl: null })} size="xl">
            <ModalBody className="text-center">
              <img src={showImgUrl} alt="" className="img-fluid" style={{ maxHeight: 720 }} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ showImgUrl: null })}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
          }
          {deleteReportId &&
          <Dialog
            isOpen={true}
            close={() => this.setState({ deleteReportId: null })}
            type="delete"
            title="Delete item"
            message="Are you sure want to delete this report/request?"
            deleteButtonText="Delete"
            onDelete={() => {
              this.props.deleteBugReport({id: this.state.deleteReportId});
              this.setState({ deleteReportId: null })}
            }
          />
          }
        </div>
      )
    }
  }
}

export default connect(
  state => ({
    //userData: state.User.get("userData"),
    bug_reports: state.Admin.get("bug_reports"),
    bug_reports_working: state.Admin.get("bug_reports_working"),
  }),
  {
    getAllBugReports,
    updateBugReport,
    deleteBugReport,
  }
)(AdminReportRequest);
