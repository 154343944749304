import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from "react-router-dom";
import {
  DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar,
  NavItem, UncontrolledDropdown, Button
} from 'reactstrap';
import './style.css';
import { logo } from "../../../assets/images";
import { ToastContainer } from 'react-toastify';
import authActions from "../../../redux/auth/actions";
import userActions from "../../../redux/user/actions";
import SubscribeModal from "../SubscribeModal";

const {
  userLogout,
} = authActions;

const {
  userReactivatePlan,
} = userActions;

class TopBar extends Component {
  render() {
    const { userData } = this.props;
    console.log("userData:", userData);

    return (
      <div className="top-bar">
        <SubscribeModal
          isOpen={!userData.is_subscription_active}
          userData={userData}
          userReactivatePlan={this.props.userReactivatePlan}
          user_billing_working={this.props.user_billing_working}
        />
        <Navbar expand="lg">
          <NavLink to="/app/dashboard" className="navbar-brand">
            <img src={logo} alt="" />
          </NavLink>
          <Nav navbar>
            <NavItem>
              <UncontrolledDropdown inNavbar>
                <DropdownToggle caret nav>{userData.username}</DropdownToggle>
                <DropdownMenu end={true}>
                  <DropdownItem tag="span">
                    <NavLink to="/app/account" className="nav-link">
                      <span>Account</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem tag="div">
                    <Button
                      color="link"
                      className="nav-link text-danger w-100 text-start"
                      onClick={() => {
                        this.props.userLogout();
                      }}
                    >
                      <span>Logout</span>
                    </Button>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>
        </Navbar>
        <ToastContainer
          autoClose={1000}
          hideProgressBar
        />
      </div>
    )
  }
}


export default withRouter(connect(
  state => ({
    userData: state.User.get("userData"),
    user_billing_working: state.User.get("user_billing_working"),
  }),
  {
    userLogout,
    userReactivatePlan,
  }
)(TopBar));
