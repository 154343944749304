import React, { Component } from 'react';
import { connect } from "react-redux";
import Metrics from "./Metrics";
import './style.css';
import ChartSearchAccept from "./ChartSearchAccept";
import {
  Card, CardBody, CardHeader,
  //Col, Row
} from "reactstrap";
//import DateIntervalDropdown from "../../shared/components/DateIntervalDropdown";
import moment from "moment";
import ChartEstRevenue from "./ChartEstRevenue";
//import ChartTeamSearchAccept from "./ChartTeamSearchAccept";
//import ChartTeamEstRevenue from "./ChartTeamEstRevenue";
import dashboardActions from "../../redux/dashboard/actions";

const {
  dashboardCumulativeGet,
  dashboardAcceptedChartGet,
  dashboardRevenueChartGet,
} = dashboardActions;

class Dashboard extends Component {
  state = {
    showIntervalDropdown: false,
    selectedInterval: {
      value: `${moment().startOf('month').format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`,
      label: 'This Month'
    },
    range: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection'
    },
  }

  componentDidMount = () => {
    this.props.dashboardCumulativeGet();
    this.props.dashboardAcceptedChartGet();
    this.props.dashboardRevenueChartGet();
  }

  toggleIntervalDropdown = () => {
    this.setState({
      showIntervalDropdown: !this.state.showIntervalDropdown
    })
  }

  createTimeDropdown = () => {
    const today = moment().format("YYYYMMDD");
    const start_month = moment().startOf('month').format("YYYYMMDD");
    const start_year = moment().startOf('year').format("YYYYMMDD");
    const start_quarter = moment().startOf('quarter').format("YYYYMMDD");
    const end_quarter = moment().endOf('quarter').format("YYYYMMDD");

    const options = [
      {value: `${start_month}-${today}`, label: 'This Month'},
      {value: `${start_quarter}-${end_quarter}`, label: 'This Quarter'},
      {value: `${start_year}-${today}`, label: 'This Year'},
    ]
    return options;
  }

  customRangesChange = (ranges) => {
    this.setState({
      range: ranges['selection'],
    })
  }

  applyRange = () => {
    const { range } = this.state;

    const start = moment(range.startDate).format("YYYYMMDD");
    const end = moment(range.endDate).format("YYYYMMDD");
    this.selectInterval({value: `${start}-${end}`, label: 'Custom'});
  }

  selectInterval = (selected) => {
    this.setState({ selectedInterval: selected })
  }

  render() {
    const {
      cumulative,
      accepted_chart,
      revenue_chart,
    } = this.props;

    return (
      <div className="main-content view-account">
        <div className="page-heading">
          <h2 className="page-title">Dashboard</h2>
          {/*
          <div className="page-heading-controls">
            <DateIntervalDropdown
              isOpen={this.state.showIntervalDropdown}
              toggle={this.toggleIntervalDropdown}
              selected={this.state.selectedInterval}
              options={this.createTimeDropdown()}
              onSelect={this.selectInterval}
              showCustom
              customRanges={[this.state.range]}
              customRangesChange={this.customRangesChange}
              applyRange={this.applyRange}
              rightDropdown
            />
          </div>
          */}
        </div>
        <div className="page-content">
          <div className="page-content-section">
            <Metrics
              data={cumulative ? cumulative : {}}
            />
          </div>
          <div className="page-content-section">
            <Card>
              <CardHeader>Accepted Items</CardHeader>
              <CardBody>
                <ChartSearchAccept
                  data={accepted_chart ? accepted_chart : []}
                />
              </CardBody>
            </Card>
          </div>
          <div className="page-content-section">
            <Card>
              <CardHeader>Estimated Revenue</CardHeader>
              <CardBody>
                <ChartEstRevenue
                  data={revenue_chart ? revenue_chart : []}
                />
              </CardBody>
            </Card>
          </div>
          {/*
          <div className="page-content-section">
            <Card>
              <CardHeader>Team Performance</CardHeader>
              <CardBody>
                <Row>
                  <Col xl={6}>
                    <ChartTeamSearchAccept />
                  </Col>
                  <Col xl={6}>
                    <ChartTeamEstRevenue />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          */}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
    cumulative: state.Dashboard.get("cumulative"),
    cumulative_working: state.Dashboard.get("cumulative_working"),
    accepted_chart: state.Dashboard.get("accepted_chart"),
    revenue_chart: state.Dashboard.get("revenue_chart"),
  }),
  {
    dashboardCumulativeGet,
    dashboardAcceptedChartGet,
    dashboardRevenueChartGet,
  }
)(Dashboard);
