import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Button, Spinner } from "reactstrap";
import {
  injectStripe,
} from "react-stripe-elements";
import { withRouter } from "react-router";
import StripeForm from "../StripeForm";

class ReactivateForm extends Component {
  onFormSubmit = (event) => {
    const { userData } = this.props;

    event.preventDefault();
    this.props.stripe
      .createToken({ name: userData.username })
      .then(response => {
        if(response.token){
          this.setState({
            stripe_error: null,
          })
          let authData = {
            stripe_token: response.token.id,
            stripe_plan: "live_plus_database",
          };
          this.props.userReactivatePlan(authData);
        } else {
          this.setState({stripe_error: response?.error?.message})
        }
      })
  }

  render() {
    return(
      <Form onSubmit={this.onFormSubmit}>
        <StripeForm />
        <div className="pt-2 text-center">
          <Button
            color="primary"
            size="lg"
            block
            disabled={this.props.user_billing_working}
          >{this.props.user_billing_working ? <Spinner size="sm" /> : "Subscribe PRO"}</Button>
        </div>
      </Form>
    )
    }
}
export default withRouter(connect(
  state => ({
  }),
  {
  }
)(injectStripe(ReactivateForm)));
