import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { digitСonversion } from '../../helpers/utils';
import Dialog from "../../shared/components/Dialog";
import TriggersTable from "./TriggersTable";
import triggersActions from "../../redux/triggers/actions";
import './style.css';

const {
  triggersGetAll,
  triggerClear,
  triggerDelete,
  triggerActivate,
} = triggersActions;

const default_triggers = [
  {id: 1, min_pscore: 100, max_pscore: 9999, min_rank: 1, max_rank: 50000, fba_slot: '4', mf_slot: 'Highest', compare_bb: true, off_amazon: 10, target_profit: 2.00, always_reject: false},
  {id: 2, min_pscore: 90, max_pscore: 99, min_rank: 50000, max_rank: 125000, fba_slot: '4', mf_slot: 'Highest', compare_bb: true, off_amazon: 10, target_profit: 2.00, always_reject: false},
  {id: 3, min_pscore: 80, max_pscore: 89, min_rank: 125000, max_rank: 250000, fba_slot: '3', mf_slot: 'Highest', compare_bb: true, off_amazon: 10, target_profit: 2.00, always_reject: false},
]

class Triggers extends Component {
  state = {
    viewDefault: false
  }

  componentDidMount = () => {
    this.props.triggersGetAll();
  }

  render() {
    const { triggers } = this.props;

    return (
      <div className="main-content view-triggers">
        <div className="page-heading">
          <h2 className="page-title">Trigger sets</h2>
          <div className="page-heading-controls">
            <NavLink
              className="btn btn-primary"
              to='/app/triggers/add'
              onClick={() => this.props.triggerClear()}
            >Add trigger set</NavLink>
          </div>
        </div>
        <div className="page-content table-responsive">
          <Table className="table-items">
            <thead>
              <tr>
                <th>Name</th>
                <th>Fulfillment Type</th>
                <th>Buy Cost</th>
                <th>Fixed Cost</th>
                <th>FBA Cost/lb</th>
                <th>Active?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {triggers?.length > 0 ? triggers?.map(item => (
              <tr key={`item-${item.id}`}>
                <td>{item.template_name}</td>
                <td>{item.fulfillment_type}</td>
                <td>{digitСonversion(item.buy_cost, 'currency')}</td>
                <td>{digitСonversion(item.fixed_cost, 'currency')}</td>
                <td>{digitСonversion(item.cost_weight, 'currency')}</td>
                <td>{item.is_active ? <Badge color="success" className="rounded-4">Active</Badge> : ''}</td>
                <td>
                  {item.is_default ?
                  <div className="hstack gap-2 justify-content-end">
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() => this.setState({viewDefault: true})}
                    >
                      View
                    </Button>
                    <NavLink
                      className="btn btn-primary btn-sm"
                      to='/app/triggers/add'
                    >
                      Create a copy
                    </NavLink>
                  </div>
                  :
                  <div className="hstack gap-2 justify-content-end">
                    <NavLink
                      className="btn btn-secondary btn-sm"
                      to={`/app/triggers/edit/${item.id}`}
                    >
                      Edit
                    </NavLink>
                    {!item.is_active &&
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => this.props.triggerActivate({
                        id: item.id,
                      })}
                      disabled={this.props.triggers_working}
                    >
                      Activate
                    </Button>
                    }
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => this.setState({ deleteId: item.id })}
                      disabled={this.props.triggers_working || item.is_active}
                    >
                      Delete
                    </Button>
                  </div>
                  }
                </td>
              </tr>
              ))
              :
              <tr>
                <td colSpan={6}>
                  <div className="text-center fw-normal">You don't have any trigger set. Please click "Add trigger set" button to create one.</div>
                </td>
              </tr>
              }
            </tbody>
          </Table>
        </div>

        {this.state.deleteId &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ deleteId: null })}
          type="delete"
          title="Delete trigger set"
          message="Are you sure want to delete this trigger set?"
          deleteButtonText="Delete trigger set"
          onDelete={() => {
            this.setState({ deleteId: null });
            this.props.triggerDelete({id: this.state.deleteId})
          }}
        />
        }

        {this.state.viewDefault &&
          <Modal isOpen={true} toggle={() => this.setState({ viewDefault: !this.state.viewDefault })} centered size="xl">
            <ModalHeader toggle={() => this.setState({ viewDefault: !this.state.viewDefault })}>View default triggers</ModalHeader>
            <ModalBody>
              <TriggersTable data={default_triggers} viewOnly />
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ viewDefault: !this.state.viewDefault })}>Close</Button>
            </ModalFooter>
          </Modal>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    triggers: state.Triggers.get("triggers"),
    triggers_working: state.Triggers.get("triggers_working"),
    userData: state.User.get("userData"),
  }),
  {
    triggersGetAll,
    triggerClear,
    triggerDelete,
    triggerActivate,
  }
)(Triggers);
