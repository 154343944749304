import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Button, Table } from "reactstrap";
import Dialog from "../../shared/components/Dialog";

const data = [
  { id: 1, name: 'Phillip Franci', username: 'franci234', visibility_mode: 'Hidden', trigger_set: 'Thrift Store' },
  { id: 2, name: 'Maren Botosh', username: 'maren.b', visibility_mode: 'Full', trigger_set: 'Library' },
  { id: 3, name: 'Marley Dias', username: 'marley_dias', visibility_mode: 'Full', trigger_set: 'Default Trigger Set' },
  { id: 4, name: 'Emery Calzoni', username: 'calzoni', visibility_mode: 'Hidden', trigger_set: 'Thrift Store' },
]

class Team extends Component {
  state = {
    deleteId: null
  }

  render() {
    return (
      <div className="main-content view-account">
        <div className="page-heading">
          <h2 className="page-title">Team</h2>
          <div className="page-heading-controls">
            <NavLink className="btn btn-primary" to="/app/team/add">Add team member</NavLink>
          </div>
        </div>
        <div className="page-content">
          <Table className="table-items">
            <thead>
              <tr>
                <th>Name</th><th>Username</th><th>Visibility Mode</th><th>Trigger Set</th><th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={`item-${item.id}`}>
                  <td>
                    <div className="hstack gap-2">
                      <span className="icon icon-user icon-22"></span>
                      <span>{item.name}</span>
                    </div>
                  </td>
                  <td>{item.username}</td>
                  <td>{item.visibility_mode}</td>
                  <td>{item.trigger_set}</td>
                  <td>
                    <div className="hstack gap-2 justify-content-end">
                      <NavLink
                        className="btn btn-secondary btn-sm"
                        to={`/app/team/edit/${item.id}`}
                      >Edit</NavLink>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => this.setState({ deleteId: item.id})}
                      >Delete</Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {this.state.deleteId &&
          <Dialog
            isOpen={true}
            close={() => this.setState({ deleteId: null })}
            type="delete"
            title="Delete account"
            message="Are you sure want to delete this account?"
            deleteButtonText="Delete account"
            onDelete={() => console.log('on delete')}
          />
          }
        </div>
      </div>
    )
  }
}

export default Team;