import moment from 'moment-timezone'
import { toast } from "react-toastify";

/**
 * self destructed message positions
 */
export const sdMessagePositions = {
	"TOP_CENTER": toast.POSITION.TOP_CENTER,
	"TOP_RIGHT": toast.POSITION.TOP_RIGHT,
	"BOTTOM_RIGHT": toast.POSITION.BOTTOM_RIGHT,
	"BOTTOM_CENTER": toast.POSITION.BOTTOM_CENTER,
	"BOTTOM_LEFT": toast.POSITION.BOTTOM_LEFT,
	"TOP_LEFT": toast.POSITION.TOP_LEFT,
}

/**
 * self destructed message
 * @param {string} message Message to display
 * @param {string} position Position
 * @param {string} type Type of message
 */
export const sdMessage = (message, message_type = "error", position = "BOTTOM_CENTER") => {
	position = sdMessagePositions[position];
	if (!position) {
		position = sdMessagePositions["BOTTOM_CENTER"];
	}
	toast[message_type](message, { position })
}

export const getValueFromObject = (object, path) => {
	const paths = path.split('.');
	while (paths.length > 1) {
		object = object[paths.shift()];
		if(!object){
			return null;
		}
	}
	return object[paths.shift()];
}

export const momentDateToISOFormatConversion = (date, format="LLL") => {
  if (date) {
    return moment(date).format(format)
  } else {
    return moment().format(format)
  }
}

export const momentTSDateToISOFormatConversion = (date, format="LLL", tz=null) => {
  let m = null;
  if (date) {
      m = moment.unix(date)
  } else {
      m = moment()
  }
  if(tz){
    m = m.tz(tz)
  }
  return m.format(format)
}

export const momentDateStrToLocalFormat = (date_str, date_format, format="LLLL") => {
  if (date_str) {
      return moment(date_str, date_format).format(format)
  } else {
      return moment().format(format)
  }
}

export const momentTSToLocalFormat = (ts, format="LLLL") => {
  if (ts) {
      return moment(ts).format(format)
  } else {
      return moment().format(format)
  }
}

/**
 * convert money currency list
 */
export const convert_money_currency_list = {
  "USD": "$",
  "EUR": "€",
  "CAD": "CA$",
  "GBP": "£",
}
export const convertMoney = (amount, precision=2, currency='USD') => {
  if(!Object.keys(convert_money_currency_list).includes(currency)){
    currency='USD';
  }
  amount = Number(amount);
  return convert_money_currency_list[currency] + amount.toFixed(precision);
}

export function digitСonversion(data, style, currency, maximumFractionDigits=2){
  const lng = navigator.language || navigator.userLanguage;
  let number = Number(data);
  let settings = {
    style: "decimal",
    maximumFractionDigits: maximumFractionDigits
  };
  if(!currency){ currency = 'USD'; }

  function transformNumberToAbbreviated(number, decPlaces) {
    decPlaces = Math.pow(10,decPlaces);
    const abbrev = [ "k", "M", "b", "t" ];
      for (let i=abbrev.length-1; i>=0; i--) {
        const size = Math.pow(10,(i+1)*3);
        if (size <= number) {
          number = Math.round(number*decPlaces/size)/decPlaces;
          number += abbrev[i];
          break;
        }
      }
    return number;
  }

  if (style) {
    switch (style) {
      case "decimal":
        settings = {
          style: "decimal",
          maximumFractionDigits: maximumFractionDigits
        };
        break;
      case "percent":
        settings = {
          style: "percent",
          maximumFractionDigits: maximumFractionDigits
        };
        number = number / 100;
        break;
      case "currency":
        settings = {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        };
        break;
      case "rank":
        return transformNumberToAbbreviated(data, 1);

      default:
        settings = {
          style: "decimal",
          maximumFractionDigits: maximumFractionDigits
        };
    }
  }

  const formatter = new Intl.NumberFormat(lng, settings);
  return formatter.format(number);
}

export function openInNewTab(url) {
  let win = window.open(url);
  win.focus();
}

export function openInNewTabExtended(url, tabName, focus=false) {
  let win = window.open(url, tabName);
  if(focus){
    win.focus();
  }
}

export function momentDisplayAge(date){
  const date_from = moment(date).from();
  return date_from;
}

export function isObjectEmpty(obj){
  for(const i in obj){ return false; }
  return true;
}

export function digitСonversionStripe(data, style, currency, maximumFractionDigits=2){
  data = data / 100;
  return digitСonversion(data, style, currency, maximumFractionDigits);
}

export function mapDisputeStatus(code){
  const status = {
    "warning_needs_response": "Needs response",
    "warning_under_review": "Under review",
    "warning_closed": "Closed",
    "needs_response": "Needs response",
    "under_review": "Under review",
    "charge_refunded": "Charge refunded",
    "won": "Won",
    "lost": "Lost",
  }
  if(!status[code]){ return code; }
  return status[code];
}

export function mapDisputeReasonDesc(code){
  const reasons = {
    "bank_cannot_process":
      `The customer claims they’re entitled to a full or partial refund because they returned the purchased
       product or didn’t fully use it, or the transaction was otherwise canceled or not fully fulfilled,
       but you haven’t yet provided a refund or credit.`,
    "check_returned": "Check returned",
    "credit_not_processed": "Credit not processed",
    "customer_initiated": "Customer initiated",
    "debit_not_authorized": "Debit notauthorized",
    "duplicate": `The customer claims they were charged multiple times for the same product or service.`,
    "fraudulent": `This is the most common reason for a dispute and happens when a cardholder claims that they didn’t authorize the payment.`,
    "general": `This is an uncategorized dispute, so contact the customer for additional details to find out why they disputed the payment.`,
    "incorrect_account_details": `The card, or account the card is connected to, is invalid.	`,
    "insufficient_funds": `The card has insufficient funds to complete the purchase.`,
    "product_not_received": `The customer claims they did not receive the products or services purchased.`,
    "product_unacceptable": `The customer received the product but claims it was defective or damaged in some way, or was not described or represented in an accurate manner prior to purchase.`,
    "subscription_canceled": `The customer claims that you continued to charge them after a subscription was canceled.`,
    "unrecognized": `The customer doesn’t recognize the payment appearing on their card statement.`,
  }
  if(!reasons[code]){ return code; }
  return reasons[code];
}

export function pluralize(count, noun, suffix = 's'){
  if(!count){ return noun; }
  return `${noun}${count !== 1 ? suffix : ''}`;
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function isEmployee(userData) {
  if(userData?.id_user_type === 2){ return true; }
  return false;
}

export function getTemplateAttributeButtons() {
  // Color guidelines
  // ----------------
  // F_ORANGE: dispute related info
  // F_PURPLE: company/bank related info
  // F_BLUE: customer related info or others

  return [
    {type: '{disputed_date}', color: 'F_ORANGE', label: 'Disputed Date'},
    {type: '{disputed_amount}', color: 'F_ORANGE', label: 'Disputed Amount'},
    {type: '{charge_amount}', color: 'F_ORANGE', label: 'Charge Amount'},
    {type: '{charge_date}', color: 'F_ORANGE', label: 'Charge Date'},
    {type: '{chargeback_category}', color: 'F_ORANGE', label: 'Chargeback Category'},
    {type: '{customer_name}', color: 'F_BLUE', label: 'Customer Name'},
    {type: '{customer_email}', color: 'F_BLUE', label: 'Customer Email'},
    {type: '{customer_created_at}',  color: 'F_BLUE', label: 'Customer Onboard Date'},
    {type: '{company}', color: 'F_PURPLE', label: 'Company'},
    {type: '{card_brand}', color: 'F_PURPLE', label: 'Card Brand'},
    {type: '{card_exp_month}', color: 'F_ORANGE', label: 'Card Exp. Month'},
    {type: '{card_exp_year}', color: 'F_ORANGE', label: 'Card Exp. Year'},
    {type: '{card_last4_digit}', color: 'F_ORANGE', label: 'Card Last 4 Digits'},
    {type: '{issuing_bank}',  color: 'F_PURPLE', label: 'Issuing Bank'},
    {type: '{customer_total_spend}',  color: 'F_BLUE', label: 'Total Spend'},
    {type: '{customer_visa_email_date}',  color: 'F_BLUE', label: 'Visa Email Date'},
    {type: '{customer_visa_email_text}',  color: 'F_BLUE', label: 'Visa Email Text'},
  ]
}

export function calculateChargebackMaxDelay(date, card) {
  // calculate chargeback
  const source = {
    "visa": 30,
    "discover": 30,
    "mastercard": 45,
    "amex": 20,
  }
  const days = source[card];
  if(!days){ return 90 - moment().diff(moment(date), "days") };
  return days - moment().diff(moment(date), "days");
}

export function calculatePercentageInt(total, active) {
  if(!active) { return total };
  return parseInt((active / total * 100), 10)
}
