import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Button, Spinner } from "reactstrap";
import {
  injectStripe,
} from "react-stripe-elements";
import { withRouter } from "react-router";
import StripeForm from "../../shared/components/StripeForm";

class PaymentCardForm extends Component {

  onFormSubmit = (event) => {
    const { userData } = this.props;

    event.preventDefault();
    this.props.stripe
      .createToken({ name: userData.username })
      .then(response => {
        if(response.token){
          this.setState({
            stripe_error: null,
          })
          let authData = {
            stripe_token: response.token.id,
          };
          this.props.userUpdateCard(authData);
        } else {
          this.setState({stripe_error: response?.error?.message})
        }
      })
  }

  render() {
    return(
      <Form onSubmit={this.onFormSubmit}>
        <StripeForm />
        <Button
          color="primary"
          size="lg"
          block
          disabled={this.props.user_billing_working}
        >{this.props.user_billing_working ? <Spinner size="sm" /> : "Update"}</Button>
      </Form>
    )
    }
}

export default withRouter(connect(
  state => ({
  }),
  {
  }
)(injectStripe(PaymentCardForm)));
