export const appConfig = {
	environments: {
		"production": "production",
		"development": "development",
	},
	backendApiUrl: {
    "production": "https://back.profithunt.io",
		"development": "http://127.0.0.1:8000",
	},
	auth: {
		refreshTokenFrequency: 60000*15,
  },
  //stripeApiTestKey: "pk_test_yM5H8yrzg8dzukLn4ypJI44J",
  stripeApiTestKey: "pk_live_Ab3sFIPfTD4kgU8MiDORNVJN",
  googleCaptchaKey: "6Lf5iZ4iAAAAAO8zecrhT_ebD_R_F5YSeLV4C-Fv",
}

