import { Map } from "immutable";

const initState = new Map({
  cumulative: {},
  cumulative_working: false,
  accepted_chart: [],
  accepted_chart_working: false,
  revenue_chart: [],
  revenue_chart_working: false,
});

export default function dashboardReducer(state = initState, action) {
  switch (action.type) {

    case "DASHBOARD_REVENUE_CHART_GET":
      return state
        .set("revenue_chart_working", true)
    case "DASHBOARD_REVENUE_CHART_GET_SUCCESS":
      return state
        .set("revenue_chart", [...action.data.data])
        .set("revenue_chart_working", false)
    case "DASHBOARD_REVENUE_CHART_GET_FAILED":
      return state
        .set("revenue_chart_working", false)

    case "DASHBOARD_ACCEPTED_CHART_GET":
      return state
        .set("accepted_chart_working", true)
    case "DASHBOARD_ACCEPTED_CHART_GET_SUCCESS":
      return state
        .set("accepted_chart", [...action.data.data])
        .set("accepted_chart_working", false)
    case "DASHBOARD_ACCEPTED_CHART_GET_FAILED":
      return state
        .set("accepted_chart_working", false)

    case "DASHBOARD_CUMULATIVE_GET":
      return state
        .set("cumulative_working", true)
    case "DASHBOARD_CUMULATIVE_GET_SUCCESS":
      return state
        .set("cumulative", {...action.data.data})
        .set("cumulative_working", false)
    case "DASHBOARD_CUMULATIVE_GET_FAILED":
      return state
        .set("cumulative_working", false)

		default:
			return state;
  }
}
