import React, { Component } from 'react';
import { connect } from "react-redux";
import { FormGroup, Col } from "reactstrap";
import {
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  injectStripe,
} from "react-stripe-elements";
import { withRouter } from "react-router";

class StripeForm extends Component {
  render() {
    //const { userData } = this.props;

    const style = {
      base: {
        backgroundColor: '#fff' ,
        color: 'rgba(39,42,57, .75)',
        fontSize: '14px',
        '::placeholder': {
          color: 'rgba(39,42,57, .3)'
        },
        ':-webkit-autofill': {
          color: 'rgba(39,42,57, .75)',
          textFillcolor: 'rgba(39,42,57, .75)',
          boxShadow: `0 0 0px 40rem #fff} inset`,
          outline: 0,
        },
      },
      invalid: {
        color: '#b64057',
        iconColor: '#b64057'
      }
    }

    return (
      <React.Fragment>
        <FormGroup row>
          <Col sm={6} xs={12} className="mb-2 mb-sm-0">
            <CardNumberElement
              // onChange={(e) => this.props.setStateForm("card_number", e, this.props.stripe)}
              style={style}
            />
          </Col>
          <Col sm={3} xs={6}>
            <CardExpiryElement
              // onChange={(e) => this.props.setStateForm("expiry", e, this.props.stripe)}
              style={style}
            />
          </Col>
          <Col sm={3} xs={6}>
            <CardCVCElement
              // onChange={(e) => this.props.setStateForm("cvc", e, this.props.stripe)}
              style={style}
            />
          </Col>
        </FormGroup>
      </React.Fragment>
    )
  }
}

export default withRouter(connect(
  state => ({
  }),
  {
  }
)(injectStripe(StripeForm)));
