import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { Container } from "reactstrap";

import { appConfig } from './config/app';
import authActions from './redux/auth/actions';
import userActions from './redux/user/actions';

import Dashboard from "./views/Dashboard";
import Register from "./views/Register";
import Login from "./views/Login";
import Settings from "./views/Settings";
import TopBar from "./shared/components/TopBar";
import LeftNavigation from "./shared/components/LeftNavigation";
import Account from "./views/Account";
import Team from "./views/Team";
import AddEditTeam from "./views/Team/AddEditTeam";
import Triggers from "./views/Triggers";
import AddEditTrigger from "./views/Triggers/AddEditTrigger";
import Admin from "./views/Admin";

const { userRefreshToken, userLogout } = authActions;
const { userGetData } = userActions;

const RouteUser = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        isLoggedIn ? (
          <TemplateUser>
            <Component {...matchProps} />
          </TemplateUser>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: matchProps.location }
            }}
          />
        )
      )}
    />
  );
}

class TemplateUser extends Component {
  render() {
    const {
      children,
      userData,
    } = this.props;

    if(userData.username){
      return (
        <div className="app">
          <div className="app-content">
            <TopBar />
            <Container fluid className="app-content-bottom">
              <LeftNavigation />
              <div className="app-content-bottom-right">
                {children}
              </div>
            </Container>
          </div>
        </div>
      )
    } else {
      <span>Loading app...</span>
    }
  }
}

TemplateUser = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
    }
  )(TemplateUser)
);

class AppPrivateRoutes extends Component {
  componentDidMount(){
    setInterval(() => {
      this.props.userRefreshToken();},
      appConfig.auth.refreshTokenFrequency
    );

    if(!this.props.userData?.id_user){
      this.props.userGetData();
    }
  }

  render(){
    return (this.props.children);
  }
}

AppPrivateRoutes = withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
      userRefreshToken,
      userGetData,
    }
  )(AppPrivateRoutes)
);

class AppRoutes extends Component {
  render() {
    const { history, isLoggedIn } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route path="/app/">
            <AppPrivateRoutes location={this.props.location}>
              <RouteUser
                exact path="/app/dashboard"
                component={Dashboard}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/settings"
                component={Settings}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/account"
                component={Account}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/team"
                component={Team}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/team/add"
                component={AddEditTeam}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/team/edit/:id"
                component={AddEditTeam}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/triggers"
                component={Triggers}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/triggers/add"
                component={AddEditTrigger}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/triggers/edit/:id"
                component={AddEditTrigger}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/triggers/view/:id"
                component={AddEditTrigger}
                isLoggedIn={isLoggedIn}
              />
              <RouteUser
                exact path="/app/admin"
                component={Admin}
                isLoggedIn={isLoggedIn}
              />
            </AppPrivateRoutes>
          </Route>
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>
    )
  }
}

export default connect(
  state => ({
    isLoggedIn: state.Auth.get("access_token") !== null,
  }),
  {
    userRefreshToken,
    userLogout,
  }
)(AppRoutes);
