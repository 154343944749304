import React, { Component } from 'react';
import { digitСonversion } from '../../helpers/utils';

const ui_data = [
  {
    icon: 'icon-search-scan',
    title: 'Total Searches',
    key: 'total_count',
  },
  {
    icon: 'icon-check-square',
    title: 'Accepts',
    key: 'total_accepts',
  },
  {
    icon: 'icon-percent-circle',
    title: 'Accept Rate',
    key: 'total_accept_rate',
  },
  {
    icon: 'icon-money',
    title: 'Est. Profit',
    key: 'est_profit',
  }
]

class Metrics extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="metrics">
        {ui_data.map((item, idx) => (
        <div className="metrics-box" key={`metrics-box-${idx}`}>
          <span className={`icon ${item.icon}`}></span>
            <h2
              className="metrics-box-text">
                {item.key === "est_profit"
                  ? data && digitСonversion(data[item.key] ? data[item.key] : 0, "currency")
                  : data && data[item.key]
                } <span className="metrics-box-title">{item.title}</span>
            </h2>
        </div>
        ))}
      </div>
    )
  }
}

export default Metrics;
