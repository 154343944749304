import React, { Component } from 'react';
import { loadingGif } from "../../../assets/images";
import './style.css';

class Loading extends Component {
  render() {
    const { text, size, inline } = this.props;
    return (
      <div className={`loading ${inline ? 'inline' : ''}`}>
        <img src={loadingGif} alt="" width={size ? size : 50} className="loading-img" />
        <div className="loading-text">{text}</div>
      </div>
    )
  }
}

export default Loading;