import { Map } from "immutable";

const initState = new Map({
  triggers: null,
  triggers_working: false,
  trigger: null,
});

export default function triggersReducer(state = initState, action) {
  switch (action.type) {

    case "TRIGGER_ACTIVATE":
      return state
        .set("triggers_working", true)
    case "TRIGGER_ACTIVATE_SUCCESS":
      var triggers_activate = state.get("triggers");
      triggers_activate = triggers_activate.map(item => {
        if(item.id === action.data.data.activated){
          item.is_active = true;
        } else {
          item.is_active = false;
        }
        return item;
      })
      return state
        .set("triggers", [...triggers_activate])
        .set("triggers_working", false)
    case "TRIGGER_ACTIVATE_FAILED":
      return state
        .set("triggers_working", false)

    case "TRIGGER_TRIGGER_DELETE":
      return state
        .set("triggers_working", true)
    case "TRIGGER_TRIGGER_DELETE_SUCCESS":
      var trigger_trigger_delete = state.get("trigger");
      trigger_trigger_delete.triggers = trigger_trigger_delete.triggers.filter(item => item.id !== action.data.data.id);
      return state
        .set("trigger", {...trigger_trigger_delete})
        .set("triggers_working", false)
    case "TRIGGER_TRIGGER_DELETE_FAILED":
      return state
        .set("triggers_working", false)

    case "TRIGGER_TRIGGER_UPDATE":
      return state
        .set("triggers_working", true)
    case "TRIGGER_TRIGGER_UPDATE_SUCCESS":
      var trigger_trigger_update = state.get("trigger");
      trigger_trigger_update.triggers = trigger_trigger_update.triggers.map(item => {
        if(item.id === action.data.data.id){
          item = {...action.data.data}
        }
        return item;
      });
      return state
        .set("trigger", {...trigger_trigger_update})
        .set("triggers_working", false)
    case "TRIGGER_TRIGGER_UPDATE_FAILED":
      return state
        .set("triggers_working", false)

    case "TRIGGER_TRIGGER_ADD":
      return state
        .set("triggers_working", true)
    case "TRIGGER_TRIGGER_ADD_SUCCESS":
      var trigger_trigger_add = state.get("trigger");
      trigger_trigger_add.triggers.push(action.data.data);
      return state
        .set("trigger", {...trigger_trigger_add})
        .set("triggers_working", false)
    case "TRIGGER_TRIGGER_ADD_FAILED":
      return state
        .set("triggers_working", false)

    case "TRIGGER_CATEGORY_ADD":
      return state
        .set("triggers_working", true)
    case "TRIGGER_CATEGORY_ADD_SUCCESS":
      var trigger_category_add = state.get("trigger");
      trigger_category_add.categories.push(action.data.data);
      return state
        .set("trigger", {...trigger_category_add})
        .set("triggers_working", false)
    case "TRIGGER_CATEGORY_ADD_FAILED":
      return state
        .set("triggers_working", false)

    case "TRIGGER_DELETE":
      return state
        .set("trigger", null)
        .set("triggers_working", true)
    case "TRIGGER_DELETE_SUCCESS":
      var triggers_delete = state.get("triggers");
      triggers_delete = triggers_delete.filter(item => item.id !== action.data.data.id);
      return state
        .set("triggers", [...triggers_delete])
        .set("triggers_working", false)
    case "TRIGGER_DELETE_FAILED":
      return state
        .set("trigger", null)
        .set("triggers_working", false)

    case "TRIGGER_ADD":
      return state
        .set("triggers_working", true)
    case "TRIGGER_ADD_SUCCESS":
      return state
        .set("triggers_working", false)
    case "TRIGGER_ADD_FAILED":
      return state
        .set("triggers_working", false)

    case "TRIGGER_CLEAR":
      return state
        .set("trigger", null)

    case "TRIGGER_UPDATE":
      return state
        .set("triggers_working", true)
    case "TRIGGER_UPDATE_SUCCESS":
      var triggers_update = state.get("triggers");
      if(triggers_update){
        triggers_update = triggers_update.map(item => {
          if(item.id === action.data.data.id){
            item = {...action.data.data}
          }
          return item;
        });
      }
      return state
        .set("triggers", triggers_update ? [...triggers_update] : null)
        .set("trigger", {...state.get("trigger"), ...action.data.data})
        .set("triggers_working", false)
    case "TRIGGER_UPDATE_FAILED":
      return state
        .set("triggers_working", false)

    case "TRIGGER_GET":
      return state
        .set("trigger", null)
        .set("triggers_working", true)
    case "TRIGGER_GET_SUCCESS":
      return state
        .set("trigger", {...action.data.data})
        .set("triggers_working", false)
    case "TRIGGER_GET_FAILED":
      return state
        .set("trigger", null)
        .set("triggers_working", false)

    case "TRIGGERS_GET_ALL":
      return state
        .set("triggers", [])
        .set("triggers_working", true)
    case "TRIGGERS_GET_ALL_SUCCESS":
      return state
        .set("triggers", [...action.data.data])
        .set("triggers_working", false)
    case "TRIGGERS_GET_ALL_FAILED":
      return state
        .set("triggers", [])
        .set("triggers_working", false)

		default:
			return state;
  }
}
