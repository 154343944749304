import React, { Component } from 'react';
import { connect } from "react-redux";
import { Badge, Button, Card, CardBody, Modal, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { digitСonversion, digitСonversionStripe } from "../../helpers/utils";
import { cardAmex, cardDiscover, cardMastercard, cardOthers, cardVisa } from "../../assets/images";
import userActions from "../../redux/user/actions";
import moment from "moment";

const {
  userGetBilling,
  userSubscriptionDelete,
} = userActions;

const plans = {
  AL: {
    plan_id: 'AL',
    price: 0,
    title: 'FREE Plan',
    description: 'Our basic and free plan for AL users.',
    active_plan_description: 'You are currently on free plan.'
  },
  PRO: {
    plan_id: 'PRO',
    price: 19.99,
    title: 'PRO Plan',
    description: 'Our premium pro plan with features such as offline mode, triggers and team.',
    active_plan_description: 'You are currently on PRO plan.'
  }
}

const cards = {
  'amex' : cardAmex,
  'discover' : cardDiscover,
  'mastercard' : cardMastercard,
  'visa' : cardVisa,
  'others' : cardOthers
}

class BillingPlan extends Component {
  state = {
    showCancelDialog: false
  }

  componentDidMount = () => {
    this.props.userGetBilling(null, true);
  }

  render() {
    const { userData, user_billing, user_billing_working } = this.props;

    return (
      <div className="billing-plan">
        <div className="page-content-section">
          <h3 className="page-content-section-title">Your Plan</h3>
          <div className="plans">
            <React.Fragment>
              {userData.subscription_plan === 'AL' &&
              <Plan
                plan_id={plans['AL'].plan_id}
                title={plans['AL'].title + '('+ digitСonversion(plans['AL'].price, 'currency') + '/month)'}
                description={plans['AL'].active_plan_description}
                userData={userData}
                onUpgradePlan={this.upgradePlan}
              />
              }
              {userData.subscription_plan === 'PRO' &&
              <Plan
                plan_id={plans['PRO'].plan_id}
                title={plans['PRO'].title + '('+ digitСonversion(plans['PRO'].price, 'currency') + '/month)'}
                description={userData.is_subscription_active ?
                  plans['PRO'].active_plan_description : plans['PRO'].description
                }
                userData={userData}
                onCancel={() => this.setState({ showCancelDialog: true })}
              />
              }
            </React.Fragment>
          </div>
        </div>
        {user_billing.length > 0 &&
        <div className="page-content-section mt-5">
          <h3 className="page-content-section-title">Billing</h3>
          <Table className="table-items">
            <thead>
              <tr>
                <th>Date</th><th>Plan</th><th>Amount</th><th>Card</th><th>Status</th><th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {user_billing.map(item => (
                <tr key={`item-${item.id}`}>
                  <td>{moment(item.created * 1000).format("LL")}</td>
                  <td>{"PRO"}</td>
                  <td>{digitСonversionStripe(item.amount, 'currency')}</td>
                  <td>
                    <img
                      src={cards[item?.payment_method_details?.card?.brand]}
                      alt={item.card_type} /> •••• •••• •••• {item?.payment_method_details?.card?.last4}
                  </td>
                  <td>
                    {item.paid
                      ? <Badge color="success">PAID</Badge>
                      : <Badge color="danger">FAILED</Badge>
                    }
                  </td>
                  <td>
                    <div className="hstack gap-2 justify-content-end">
                      <a
                        className="btn btn-info btn-sm"
                        href={item?.receipt_url}
                        target="_blank"
                        rel="noreferrer"
                      >Download invoice</a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination listClassName="justify-content-center" size="sm">
            <PaginationItem>
              <PaginationLink
                onClick={() => this.props.userGetBilling({
                  starting_after: user_billing[user_billing.length - 1].id,
                })}
              >Older</PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
        }
        {this.state.showCancelDialog &&
        <Modal
          modalClassName="dialog dialog-error"
          isOpen={true}
          toggle={() => this.setState({ showCancelDialog: !this.state.showCancelDialog })}
          centered
        >
          <ModalBody>
            <div className="dialog-icon">
              <span className="icon icon-warning"></span>
            </div>
            <div className="dialog-text">
              <h6 className="dialog-title">Cancel Plan</h6>
              <p className="dialog-message">Are you sure you want to cancel PRO plan?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 w-100">
              <Button
                color="success"
                onClick={() => this.setState({ showCancelDialog: false })}
                block
                disabled={user_billing_working}
              >No, keep plan</Button>
              <Button
                color="danger"
                onClick={() => this.props.userSubscriptionDelete()}
                block
                disabled={user_billing_working}
              >Yes, cancel plan</Button>
            </div>
          </ModalFooter>
        </Modal>
        }
      </div>
    )
  }
}

const Plan = ({ plan_id, title, description, userData, onSubscribe, onCancel }) => (
  <Card className={`plan-item mt-3 ${userData.is_subscription_active ? 'active' : ''}`}>
    <CardBody className="d-flex justify-content-between">
      <div>
        <div className="d-flex gap-2">
          <h4 className="m-0 h6">{title}</h4>
          {userData.is_subscription_active ?
          <Badge color="success">ACTIVE</Badge>
          :
          <Badge color="danger">NOT ACTIVE</Badge>
          }
        </div>
        <p className="m-0">{description}</p>
      </div>
      {plan_id === 'PRO' &&
      <div className="plan-item-action">
        {userData.is_subscription_active ?
          <Button color="danger" onClick={onCancel}>Cancel Plan</Button>
          :
          <Button color="primary" onClick={onSubscribe}>Subscribe</Button>
        }
      </div>
      }
    </CardBody>
  </Card>
)

export default connect(
  state => ({
    userData: state.User.get("userData"),
    user_billing: state.User.get("user_billing"),
    user_billing_working: state.User.get("user_billing_working"),
  }),
  {
    userGetBilling,
    userSubscriptionDelete,
  }
)(BillingPlan);
