import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { Button, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink as NavLinkTab, TabContent, TabPane } from "reactstrap";
import Dialog from "../../shared/components/Dialog";
import TriggersTable from "./TriggersTable";
import triggersActions from "../../redux/triggers/actions";

const {
  triggerGet,
  triggerUpdate,
  triggerClear,
  triggerAdd,
  triggerCategoryAdd,
} = triggersActions;

const fulfillment_types = [
  {label: 'FBA', value: 'FBA'},
  {label: 'MF', value: 'MF'}
]

class AddEditTrigger extends Component {
  state = {
    showConfirmDelete: false,
    activeTab: null,
    name: null,
    selected_type: null,
    buy_cost: null,
    cost_weight: null,
    categories: [],
    showAddCategoryModal: false,
    category_name: "",
    fixed_cost: null,
  }

  componentWillMount = () => {
    this.props.triggerClear();
  }

  componentDidMount = () => {
    const params = this.props.match.params;
    if(params?.id){
      this.props.triggerGet({id: params?.id});
    }
    /*
    if(params?.add_method && params.add_method === 'copy' ) {
      this.setState({
        name: 'Copy of Default Trigger Set',
        selected_type: fulfillment_types[0],
        buy_cost: 0,
        fba_cost: 0.3
      })
    }
    */
  }

  componentDidUpdate = (pp) => {
    if(
      !this.state.activeTab
        && this.props.trigger?.categories
        && !pp.trigger?.categories
    ){
      this.setState({activeTab: this.props.trigger?.categories[0].id})
    }
  }

  updateTrigger = () => {
    const data = {
      template_name: this.state.name ? this.state.name : null,
      buy_cost: this.state.buy_cost ? this.state.buy_cost : null,
      cost_weight: this.state.cost_weight ? this.state.cost_weight : null,
      fulfillment_type: this.state.selected_type?.value ? this.state.selected_type?.value : null,
      fixed_cost: this.state.fixed_cost ? this.state.fixed_cost : null,
    }

    this.props.triggerUpdate({
      id: this.props.trigger?.id,
      update_data: data,
    })
  }

  addTrigger = () => {
    const data = {
      template_name: this.state.name,
      buy_cost: this.state.buy_cost,
      cost_weight: this.state.cost_weight,
      fulfillment_type: this.state.selected_type.value,
      fixed_cost: this.state.fixed_cost,
    }

    this.props.triggerAdd(data);
  }

  addCategory = () => {
    this.props.triggerCategoryAdd({
      id_template: this.props.trigger.id,
      category_name: this.state.category_name,
    })
    this.toggleCategoryModal()
  }

  toggleCategoryModal = () => {
    this.setState({
      showAddCategoryModal: !this.state.showAddCategoryModal,
      category_name: ""
    })
  }

  render() {
    const { trigger } = this.props;
    const {
      showConfirmDelete,
      activeTab,
      name,
      selected_type,
      buy_cost,
      cost_weight,
      showAddCategoryModal,
      category_name,
      fixed_cost,
    } = this.state

    //console.log('TRIGGER', trigger);

    return (
      <div className="main-content view-trigger-set">
        <div className="page-heading">
          <h2 className="page-title">
            {trigger ? 'Edit' : 'Add'} trigger set
          </h2>
          <div className="page-heading-controls">
            <NavLink className="btn btn-secondary" to="/app/triggers" exact>Back</NavLink>
            {/*
            {trigger &&
              <Button
                color="danger"
                onClick={() => this.setState({ showConfirmDelete: true })}
              >Delete trigger set</Button>
            }
            */}
            <Button
              color="primary"
              disabled={
                trigger
                  ? (name === null
                      && buy_cost === null
                      && cost_weight === null
                      && fixed_cost === null
                      && selected_type === null
                    ) || this.props.triggers_working
                  : (name === null
                      || buy_cost === null
                      || cost_weight === null
                      || fixed_cost === null
                      || selected_type === null
                    ) || this.props.triggers_working
              }
              onClick={
                trigger
                  ? () => this.updateTrigger()
                  : () => this.addTrigger()
              }
            >
              Save {trigger ? 'changes' : ''}
            </Button>
          </div>
        </div>
        <div className="page-content">
          <div className="page-content-section">
            <div className="trigger-set-main">
              <FormGroup>
                <Label>Trigger Set Name</Label>
                <Input
                  value={name === null ? trigger?.template_name : name}
                  onChange={e => this.setState({ name: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Fulfillment Type</Label>
                <Select
                  options={fulfillment_types}
                  isSearchable={false}
                  value={selected_type === null ? fulfillment_types.find(item => item.value === trigger?.fulfillment_type) : selected_type}
                  onChange={value => this.setState({ selected_type: value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Buy Cost</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="number"
                    value={buy_cost === null ? trigger?.buy_cost : buy_cost}
                    onChange={e => this.setState({ buy_cost: e.target.value })}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>FBA Cost/lb</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="number"
                    value={cost_weight === null ? trigger?.cost_weight : cost_weight}
                    onChange={e => this.setState({ cost_weight: e.target.value })}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>MF Fixed Cost</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="number"
                    value={fixed_cost === null ? trigger?.fixed_cost : fixed_cost}
                    onChange={e => this.setState({ fixed_cost: e.target.value })}
                  />
                </InputGroup>
              </FormGroup>
            </div>
          </div>
          <div className="page-content-section">
            <h3 className="page-content-section-title">Triggers</h3>
            <Nav tabs className="triggers-tab">
              {trigger?.categories?.map((item, idx) => (
                <NavItem key={`tab-item-${item.id}`}>
                  <NavLinkTab
                    role="button"
                    active={activeTab === item.id}
                    onClick={() => this.setState({activeTab: item.id})}
                    className="text-capitalize"
                  >{item.category_name}</NavLinkTab>
                </NavItem>
              ))}
              {/*
              <NavItem>
                <NavLinkTab
                  role="button"
                  className="text-primary bg-white"
                  onClick={() => this.setState({ showAddCategoryModal: true })}
                >+ Add category</NavLinkTab>
              </NavItem>
              */}
            </Nav>
            <TabContent activeTab={activeTab} className="pt-3">
              {trigger?.categories?.map((item, idx) => (
                <TabPane key={`tab-pane-${item.id}`} tabId={item.id}>
                  {activeTab === item.id &&
                    <TriggersTable
                      data={trigger?.triggers
                          ? trigger?.triggers.filter(t => t.id_category === activeTab)
                          : []
                      }
                      id_category={item.id}
                    />
                  }
                </TabPane>
              ))}
            </TabContent>
            {!trigger?.categories &&
            <p className="text-danger">Please save your trigger set first to start adding trigger items.</p>
            }
          </div>
        </div>
        {showConfirmDelete &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ showConfirmDelete: false })}
          type="delete"
          title="Delete trigger set"
          message="Are you sure want to delete this trigger set?"
          deleteButtonText="Delete trigger set"
          onDelete={() => console.log('delete trigger set then back to trigger set list page')}
        />
        }
        {showAddCategoryModal &&
          <Modal isOpen={true} toggle={this.toggleCategoryModal} centered>
            <ModalHeader toggle={this.toggleCategoryModal}>
              Add new trigger category
            </ModalHeader>
            <ModalBody>
              <Label>Category Name</Label>
              <Input
                placeholder="Provide category name for your triggers"
                value={category_name}
                onChange={(e) => this.setState({ category_name: e.target.value })}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.toggleCategoryModal}>Cancel</Button>
              <Button
                color="primary"
                onClick={this.addCategory}>Save</Button>
            </ModalFooter>
          </Modal>
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    trigger: state.Triggers.get("trigger"),
    triggers_working: state.Triggers.get("triggers_working"),
    userData: state.User.get("userData"),
  }),
  {
    triggerGet,
    triggerUpdate,
    triggerClear,
    triggerAdd,
    triggerCategoryAdd,
  }
)(AddEditTrigger);
