import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Select from "react-select";
import { FlagIcon } from "react-flag-kit";
import userActions from "../../redux/user/actions";
import BillingPlan from "./BillingPlan";
import './style.css';
import Payment from "./Payment";

const {
  userAmazonAuthStart,
  userGetCards,
  userUpdateCard,
} = userActions;

const get_marketplace_mapping = (as_array=false, by_value=null) => {
  const mapping = {
    "A2EUQ1WTGCTBG2":
      { value: 'CA', label: 'Amazon.ca', flag: "CA", marketplaceID: "A2EUQ1WTGCTBG2" },
    "ATVPDKIKX0DER":
      { value: 'US', label: 'Amazon.com', flag: "US", marketplaceID: "ATVPDKIKX0DER" },
    "A1F83G8C2ARO7P":
      { value: 'GB', label: 'Amazon.uk', flag: "GB", marketplaceID: "A1F83G8C2ARO7P" },
  }
  if(as_array){
    const array = Object.keys(mapping).map(function(key){
        return mapping[key];
    });
    return array;
  }
  if(by_value){
    return mapping[by_value] || null;
  }
  return null;
}

const labelFlag = (data) => (
  <div className="hstack gap-2">
    <span><FlagIcon code={data.flag} className="rounded-1" /></span>
    <span>{data.marketplaceID}</span>
  </div>
);

class Settings extends Component {
  state = {
    marketplaceId: "",
    activeTab: "1"
  }

  render() {
    return (
      <div className="main-content view-settings">
        <h2 className="page-title">Settings</h2>
        <div className="page-content">
          <Nav pills>
            <NavItem>
              <NavLink
                role="button"
                active={this.state.activeTab === "1"}
                onClick={() => this.setState({ activeTab: "1" })}
              >Generals</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                role="button"
                active={this.state.activeTab === "2"}
                onClick={() => this.setState({ activeTab: "2" })}
              >Billing and Plan</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                role="button"
                active={this.state.activeTab === "3"}
                onClick={() => this.setState({ activeTab: "3" })}
              >Payment</NavLink>
            </NavItem>
          </Nav>
          <TabContent className="py-4" activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col xl={4}>
                  <Card>
                    <CardHeader>Amazon</CardHeader>
                    <CardBody>
                      <p>Authorize/Re-authorize AccelerList</p>
                      <FormGroup>
                        <Label>Marketplace ID</Label>
                        <Select
                          options={get_marketplace_mapping(true)}
                          getOptionLabel={labelFlag}
                          valueRenderer={labelFlag}
                          value={get_marketplace_mapping(false, this.state.marketplaceId ? this.state.marketplaceId : this.props.userData?.marketplace_id)}
                          onChange={selected => this.setState({ marketplaceId: selected.marketplaceID })}
                          clearable={false}
                        />
                      </FormGroup>
                      <Button
                        color="primary"
                        disabled={this.props.amazon_auth_working
                            || (!this.state.marketplaceId && !this.props.userData?.marketplace_id)
                        }
                        onClick={() => this.props.userAmazonAuthStart({
                          "id_user": this.props.userData?.al_id,
                          "marketplace_id": this.state.marketplaceId,
                        })}
                      >Authorize</Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              {this.state.activeTab === "2" && <BillingPlan />}
            </TabPane>
            <TabPane tabId="3">
              {this.state.activeTab === "3" &&
              <Payment
                userGetCards={this.props.userGetCards}
                user_billing_working={this.props.user_billing_working}
                cards={this.props.cards}
                userData={this.props.userData}
                userUpdateCard={this.props.userUpdateCard}
              />
              }
            </TabPane>
          </TabContent>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    amazon_auth_working: state.User.get("amazon_auth_working"),
    userData: state.User.get("userData"),
    user_billing_working: state.User.get("user_billing_working"),
    cards: state.User.get("cards"),
  }),
  {
    userAmazonAuthStart,
    userGetCards,
    userUpdateCard,
  }
)(Settings);
