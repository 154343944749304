const triggersActions = {
  TRIGGERS_GET_ALL: "TRIGGERS_GET_ALL",
  TRIGGER_GET: "TRIGGER_GET",
  TRIGGER_UPDATE: "TRIGGER_UPDATE",
  TRIGGER_CLEAR: "TRIGGER_CLEAR",
  TRIGGER_ADD: "TRIGGER_ADD",
  TRIGGER_DELETE: "TRIGGER_DELETE",
  TRIGGER_CATEGORY_ADD: "TRIGGER_CATEGORY_ADD",
  TRIGGER_TRIGGER_ADD: "TRIGGER_TRIGGER_ADD",
  TRIGGER_TRIGGER_UPDATE: "TRIGGER_TRIGGER_UPDATE",
  TRIGGER_TRIGGER_DELETE: "TRIGGER_TRIGGER_DELETE",
  TRIGGER_ACTIVATE: "TRIGGER_ACTIVATE",

  triggerActivate: (data) => ({
    type: triggersActions.TRIGGER_ACTIVATE,
    data,
  }),

  triggerTriggerDelete: (data) => ({
    type: triggersActions.TRIGGER_TRIGGER_DELETE,
    data,
  }),

  triggerTriggerUpdate: (data) => ({
    type: triggersActions.TRIGGER_TRIGGER_UPDATE,
    data,
  }),

  triggerTriggerAdd: (data) => ({
    type: triggersActions.TRIGGER_TRIGGER_ADD,
    data,
  }),

  triggerCategoryAdd: (data) => ({
    type: triggersActions.TRIGGER_CATEGORY_ADD,
    data,
  }),

  triggerDelete: (data) => ({
    type: triggersActions.TRIGGER_DELETE,
    data,
  }),

  triggerAdd: (data) => ({
    type: triggersActions.TRIGGER_ADD,
    data,
  }),

  triggerClear: (data) => ({
    type: triggersActions.TRIGGER_CLEAR,
    data,
  }),

  triggerUpdate: (data) => ({
    type: triggersActions.TRIGGER_UPDATE,
    data,
  }),

  triggerGet: (data) => ({
    type: triggersActions.TRIGGER_GET,
    data,
  }),

  triggersGetAll: (data) => ({
    type: triggersActions.TRIGGERS_GET_ALL,
    data,
  }),
}

export default triggersActions;
