import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from "moment";
import chart_config from "./chart_config";
import { digitСonversion } from '../../helpers/utils';

class ChartEstRevenue extends Component {

  formatXAxis = (str) => {
    return str
      ? moment(str).format("M/D")
      : ""
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={this.props.data}
          margin={{
            top: 30,
            left: 15
          }}
        >
          <CartesianGrid
            stroke={chart_config.gridStroke}
            vertical={false}
          />
          <XAxis
            dataKey="date"
            stroke={chart_config.axisStroke}
            tick={{fill: chart_config.axisFill}}
            tickFormatter={(e) => this.formatXAxis(e)}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{fill: chart_config.axisFill}}
            tickFormatter={v => digitСonversion(v, 'currency')}
          />
          <Tooltip
            cursor={false}
            contentStyle={chart_config.tooltipContentStyle}
            formatter={v => digitСonversion(v, 'currency')}
            labelStyle={{ color: chart_config.axisFill }}
            itemStyle={{ color: chart_config.axisFill }}
            labelFormatter={(e) => this.formatXAxis(e)}
          />
          <Legend
            formatter={chart_config.legendFormatter}
          />
          <Bar
            dataKey="sum_profit"
            name="Est. Revenue"
            maxBarSize={chart_config.maxBarSize}
            radius={chart_config.barRadius}
            fill={chart_config.colorGreen}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

export default ChartEstRevenue;
