import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* triggersGetAll() {
  yield commonSaga(
    actions.TRIGGERS_GET_ALL,
    apiCalls.get,
    apiEndpoints.application.template_pricing_all,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* triggerGet() {
  yield commonSaga(
    actions.TRIGGER_GET,
    apiCalls.get,
    apiEndpoints.application.template_pricing,
    {
      ...default_config,
      ...{
        success_show: false,
        failed_show: false,
      }
    }
  );
}

export function* triggerUpdate() {
  yield commonSaga(
    actions.TRIGGER_UPDATE,
    apiCalls.put,
    apiEndpoints.application.template_pricing,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* triggerAdd() {
  yield commonSaga(
    actions.TRIGGER_ADD,
    apiCalls.post,
    apiEndpoints.application.template_pricing,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* triggerDelete() {
  yield commonSaga(
    actions.TRIGGER_DELETE,
    apiCalls.delete,
    apiEndpoints.application.template_pricing,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* triggerCategoryAdd() {
  yield commonSaga(
    actions.TRIGGER_CATEGORY_ADD,
    apiCalls.post,
    apiEndpoints.application.template_pricing_category,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* triggerTriggerAdd() {
  yield commonSaga(
    actions.TRIGGER_TRIGGER_ADD,
    apiCalls.post,
    apiEndpoints.application.template_pricing_trigger,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* triggerTriggerUpdate() {
  yield commonSaga(
    actions.TRIGGER_TRIGGER_UPDATE,
    apiCalls.put,
    apiEndpoints.application.template_pricing_trigger,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* triggerTriggerDelete() {
  yield commonSaga(
    actions.TRIGGER_TRIGGER_DELETE,
    apiCalls.delete,
    apiEndpoints.application.template_pricing_trigger,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* triggerActivate() {
  yield commonSaga(
    actions.TRIGGER_ACTIVATE,
    apiCalls.put,
    apiEndpoints.application.template_pricing_activate,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* triggersSaga() {
	yield all([
    fork(triggersGetAll),
    fork(triggerGet),
    fork(triggerUpdate),
    fork(triggerAdd),
    fork(triggerDelete),
    fork(triggerCategoryAdd),
    fork(triggerTriggerAdd),
    fork(triggerTriggerUpdate),
    fork(triggerTriggerDelete),
    fork(triggerActivate),
	]);
}
