import Auth from "./auth/reducer";
import User from "./user/reducer";
import Triggers from "./triggers/reducer";
import Dashboard from "./dashboard/reducer";
import Admin from "./admin/reducer";

const Reducers = {
  Auth,
  User,
  Triggers,
  Dashboard,
  Admin,
};

export default Reducers;
