const chart_config = {
  gridStroke: '#ECEFF6',
  axisStroke: '#D0D4DE',
  axisFill: '#6A7186',
  maxBarSize: 28,
  barRadius: [6,6,0,0],
  barVerticalRadius: [0,6,6,0],
  dateFormat: 'MM/DD',
  tooltipContentStyle: {
    borderRadius: 4,
    border: 0,
    boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.5)'
  },
  colorBlack: '#3C404C',
  colorGreen: '#4cab02',
  colorBlue: '#3D5FC0',
  colorLightBlue: '#e0e4ee',
  legendFormatter: (value, entry) => <span style={{ color: '#6A7186' }}>{value}</span>
}
export default chart_config;